import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EntiteService } from '../entite.service';
import { Entite } from '../../../e2e/src/entite';

@Component({
  selector: 'app-updateentite',
  templateUrl: './updateentite.component.html',
  styleUrls: ['./updateentite.component.css']
})
export class UpdateentiteComponent implements OnInit {
entite:any;
entites:Entite[]=[]
id:number=0;
  constructor(
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private entiteService:EntiteService,

  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params)=>{
        this.entiteService.getEntitebyid(params.id).subscribe(
          (response)=>{
            this.entite=response;
            this.id=params.id;

          }
        )
      },
      (error)=>{
        alert("Problemes d'acces aux API");
      }
    );
    this.entiteService.listeEntite().subscribe(
      (result)=>{
          this.entites=result;
      }
    )
  }
  modifierentite(){
    this.entiteService.updateEntite(this.id, this.entite).subscribe(
      (response)=>{
        this.router.navigate(['listentite']);
      }
    )
  }
}
