import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntiteService } from '../entite.service';
import { Entite } from '../../../e2e/src/entite';

@Component({
  selector: 'app-entiteliste',
  templateUrl: './entiteliste.component.html',
  styleUrls: ['./entiteliste.component.css']
})
export class EntitelisteComponent implements OnInit {
entites:Entite[]=[];
dtOptions1: DataTables.Settings = {};
  constructor(
    private entiteService:EntiteService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.entiteService.listeEntite().subscribe(
      (response)=>{
        setTimeout(()=>{
          $('#datatableexample').DataTable( {
            language: {
              processing:     "Traitement en cours...",
              search:         "Rechercher&nbsp;:",
              lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
              info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
              infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
              infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
              infoPostFix:    "",
              loadingRecords: "Chargement en cours...",
              zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
              emptyTable:     "Aucune donnée disponible dans le tableau",
              paginate: {
                  first:      "Premier",
                  previous:   "Pr&eacute;c&eacute;dent",
                  next:       "Suivant",
                  last:       "Dernier"
              },
              aria: {
                  sortAscending:  ": activer pour trier la colonne par ordre croissant",
                  sortDescending: ": activer pour trier la colonne par ordre décroissant"
              }
          },
            pagingType: 'full_numbers',
            pageLength: 5,
            processing: true,
            lengthMenu : [5, 10, 25],
            order:[[1,"desc"]]
        } );
        }, 1);
        this.entites=response;
      },
      (error)=>{
        alert("Probleme d'acces aux Api");
      }
    );
  }
  redirectajouterentite(){
    this.router.navigate(['ajouterentite']);
  }
  redirectmodifierentite(id:number){
    this.router.navigate(['modifierentite', id]);
  }

}
