import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MobiliteService } from '../mobilite.service';
import { Mobilite } from '../mobilite';

@Component({
  selector: 'app-listermobilite',
  templateUrl: './listermobilite.component.html',
  styleUrls: ['./listermobilite.component.css']
})
export class ListermobiliteComponent implements OnInit {
mobilites : Mobilite[]=[];
dtOptions: DataTables.Settings = {};
modalRef: any;
id:number=0;
  constructor(
    private router: Router,
    private mobiliteService:MobiliteService
  ) { }

  ngOnInit(){
    this.mobiliteService.listeMobilite().subscribe(
      (response)=>{
        setTimeout(()=>{
          $('#datatableexample').DataTable( {
            language: {
              processing:     "Traitement en cours...",
              search:         "Rechercher&nbsp;:",
              lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
              info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
              infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
              infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
              infoPostFix:    "",
              loadingRecords: "Chargement en cours...",
              zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
              emptyTable:     "Aucune donnée disponible dans le tableau",
              paginate: {
                  first:      "Premier",
                  previous:   "Pr&eacute;c&eacute;dent",
                  next:       "Suivant",
                  last:       "Dernier"
              },
              aria: {
                  sortAscending:  ": activer pour trier la colonne par ordre croissant",
                  sortDescending: ": activer pour trier la colonne par ordre décroissant"
              }
          },
            pagingType: 'full_numbers',
            pageLength: 5,
            processing: true,
            lengthMenu : [5, 10, 25],
            order:[[1,"desc"]]
        } );
        }, 1);
        console.log(response);
        this.mobilites= response;
      }
    );

  }

 public  redirectajoutermobilite(){
    this.router.navigate(['ajoutermobilite']);
    }
  public redirectmodifiermobilite(id:number){
  this.router.navigate(['modifiermobilite',id]);
  }
 public  detailmobilite(id:number){
    this.router.navigate(['modifierpersonne', id]);
  }

}
