import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthentificationService } from '../authentification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  id:number =0;


  constructor(
    public authentificationService:AuthentificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const x = localStorage.getItem('user_id');
    console.log(x);
    this.id=Number(x)
  }
logout(){
this.authentificationService.logout();
      this.router.navigate(['login']);
      window.location.href = "/login";
}
public logoutAuto(){
  setTimeout(() => {
    this.authentificationService.logout();
      this.router.navigate(['login']);
  }, 200);
}
}
