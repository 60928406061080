<div class="row col-md-10 text-center">

<div class="block full">
  <!-- All Products Title -->
  <div class="block-title">
      <div class="block-options pull-right">
          <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default" data-toggle="tooltip" title="Settings"><i class="fa fa-cog"></i></a>
      </div>
      <h2><strong>liste</strong>Types de mobilite</h2>
      <button class="btn btn-success" (click)="redirectajoutertypemobilite()" ><span><i class="fa fa-plus">Ajouter</i></span></button>
  </div>
  <!-- END All Products Title -->

  <!-- All Products Content -->
  <table id="ecom-products" class="table table-bordered table-striped table-vcenter">
      <thead>
          <tr>
              <th class="text-center">Type mobilite</th>
              <th>Action</th>

          </tr>
      </thead>
      <tbody>
        <tr *ngFor="let listetypemobilite of listetypemobilites">
          <td>{{i +1}} </td>
          <td>{{listetypemobilite['libelle'] }} </td>
          <td>
            <button class="btn btn-default"(click)="redirectajoutertypemobilite()" >Modifier</button>

          </td>
        </tr>
      </tbody>
  </table>
  <!-- END All Products Content -->
</div>

