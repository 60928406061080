import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ValeurService } from '../valeur.service';
import { Valeur } from '../valeur';

@Component({
  selector: 'app-listevaleur',
  templateUrl: './listevaleur.component.html',
  styleUrls: ['./listevaleur.component.css']
})
export class ListevaleurComponent implements OnInit {
listeValeurs: Valeur[] = [];
  constructor(
    private valeurService:ValeurService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.valeurService.listeValeur().subscribe(
      (response)=>{
          this.listeValeurs = response;
      },
      (error)=>{
        alert("Probleme d'acces aux API");
      }
    );
  }
  redirectajoutervaleur(){
      this.router.navigate(['ajoutervaleur']);
  }
  redirectmodifiervaleur(){
    this.router.navigate(['ajoutervaleur']);
}
}
