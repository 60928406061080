<div class="col-md-10">
  <!-- Basic Form Elements Block -->
  <div class="block">
      <!-- Basic Form Elements Title -->
      <div class="block-title">
          <div class="block-options pull-right">
              <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default toggle-bordered enable-tooltip" data-toggle="button" title="Toggles .form-bordered class"></a>
          </div>
          <h2><strong>Mettre à jour le profil</strong></h2>
      </div>

      <!-- END Form Elements Title -->

      <!-- Basic Form Elements Content -->
      <form #formvaleur="ngForm" class="form-horizontal form-bordered">

        <div class="form-group">
          <label class="col-md-3 control-label" for="matricule">Matricule</label>
          <div class="col-md-6">
            <input type="text" id="inputMatricule"
                required minlength="4"
                name="matricule"
                [(ngModel)]="user.matricule"
                #inputMatricule="ngModel"
                class="form-control" >
                <div class="alert alert-danger" *ngIf="inputMatricule.invalid && inputMatricule.touched" >
                  Le champ matricule est un champ requis
               </div>

          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="nom">Nom</label>
          <div class="col-md-6">
            <input type="text"
                  required
                  name="nom"
                  [(ngModel)]="user.nom"
                  #inputNom="ngModel"
                  class="form-control" >
                  <div class="alert alert-danger" *ngIf="inputNom.invalid && inputNom.touched" >
                    Le champ prenom est un champ requis
                 </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="prenom">Prenom</label>
          <div class="col-md-6">
            <input type="text"
            name="prenom"
            required
            [(ngModel)]="user.prenom"
            #inputPrenom="ngModel"
            class="form-control" >
            <div class="alert alert-danger" *ngIf="inputPrenom.invalid && inputPrenom.touched" >
              Le champ nom est un champ requis
           </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="email">Email</label>
          <div class="col-md-6">
            <input type="text"

            email required
            name="email"
            [(ngModel)]="user.email"
            #inputEmail="ngModel"
            class="form-control" >
            <div class="alert alert-danger" *ngIf="inputEmail.invalid && inputEmail.touched" >
              Ceci n'est pas une adresse email
           </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="passactuel">Mot de passe actuel</label>
          <div class="col-md-6">
            <input type="password"
            name="passactuel"
           ngModel
            #inputpassactuel="ngModel"
            class="form-control" >
            <div class="alert alert-danger" *ngIf="inputpassactuel.invalid && (inputpassactuel.dirty || inputpassactuel.touched)" >
            SVP Entrer le mot de passe actuel
           </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="passwordssss">Nouveau mot de password</label>
          <div class="col-md-6">
            <input type="password"
             minlength="4"
            name="passwordssss"
            [(ngModel)]="user.password"
            #inputPassword="ngModel"
            ngModel
            class="form-control" >
            <div class="alert alert-danger" *ngIf="inputPassword.invalid && (inputPassword.dirty || inputPassword.touched)" >
              Taper un mot de passe de 4 caractere au minimum
           </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="password_confirmation">Taper le nouveau mot de passe</label>
          <div class="col-md-6">
            <input type="password"
            ngModel required
             name="password_confirmation"
             #inputPasswordConfirm="ngModel"
             class="form-control" >
           <div class="alert alert-danger" *ngIf="(inputPasswordConfirm.dirty || inputPasswordConfirm.touched)&&(inputPassword.value != inputPasswordConfirm.value)" >
            Taper un mot de passe de 4 caractere au minimum
          </div>
          </div>
        </div>
        <div class="form-group form-actions">
              <div class="col-md-9 col-md-offset-3">
                <button [disabled]="formvaleur.invalid" (click)= "update()" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Enregistrer</button>
              </div>
          </div>
      </form>
      <!-- END Basic Form Elements Content -->
  </div>
  <!-- END Basic Form Elements Block -->
</div>

