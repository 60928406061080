import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ValeurService } from '../valeur.service';
import { ParametreService } from '../parametre.service';
import { Parametre } from '../parametre';

@Component({
  selector: 'app-ajoutervaleur',
  templateUrl: './ajoutervaleur.component.html',
  styleUrls: ['./ajoutervaleur.component.css']
})
export class AjoutervaleurComponent implements OnInit {
parametres:Parametre[]=[];
par_id:number=1;
  constructor(
    private valeurService:ValeurService,
    private ParametreService:ParametreService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.ParametreService.listeParametre().subscribe(
      (response)=>{
        this.parametres=response;
      },
      (error)=>{
        alert("probleme d'acces à l'api");
      }
    );
  }
  ajoutervaleur(formulaire:NgForm){
   console.log(formulaire.value);
    this.valeurService.addValeur(formulaire.value).subscribe(
      (response)=>{
        this.router.navigate(['listevaleur']);
      }
    );

  }

}
