import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TypemobiliteService } from '../typemobilite.service';
import { Typemobilite } from '../typemobilite';

@Component({
  selector: 'app-listetypemobilite',
  templateUrl: './listetypemobilite.component.html',
  styleUrls: ['./listetypemobilite.component.css']
})
export class ListetypemobiliteComponent implements OnInit {
  listetypemobilites: Typemobilite[] = [];
  i=0;
  constructor(

    private router:Router,
    private typemobiliteservice:TypemobiliteService
  ) { }

  ngOnInit(): void {
    this.typemobiliteservice.listetypemobilite().subscribe(
      (response)=>{
        this.listetypemobilites=response;
      },
      (error)=>{
        alert("probleme d'acces à l'api");
      }
    );

  }
  redirectajoutertypemobilite(){
      this.router.navigate(['ajoutertypemobilite']);
  }
  updateEntitie(id:number){
    this.router.navigate(['modifiertypemobilite',id]);
}
}
