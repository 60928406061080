import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { FlashMessagesService } from "angular2-flash-messages";
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate{
  constructor(
    private router: Router,

) { }
  canActivate(route:ActivatedRouteSnapshot,status:RouterStateSnapshot):Observable<boolean| UrlTree >| Promise<boolean| UrlTree>|boolean
  {
    const token = localStorage.getItem('token')
    if(token!=null){
      return true;
    }
    else{
      this.router.navigate(['login']);
      return false;
    }

  }
}
