import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
// link='http://10.178.24.15/mobiliteback/public/api/login';
apiUrl = environment.apiUrl;
  link=`${this.apiUrl}/login`;

  constructor(
  private http:HttpClient,
  private router:Router,
  ) { }
  isLogged(){
    return !!localStorage.getItem('token');
  }
  isfirstconn(){
    const x=localStorage.getItem('firstcon')
    const val = Number(x);
    if(val==1){
      return true;
    }
    else{
      return false;
    }
  }
  isdg(){
    return !!(localStorage.getItem('role')=='dg');
  }
  issg(){
    return !!(localStorage.getItem('role')=='sg');
  }
  ismanger(){
    return !!(localStorage.getItem('role')=='manager');
  }
  isuser(){
    return !!(localStorage.getItem('role')=='user');
  }
  isadmin(){
    return !!(localStorage.getItem('role')=='admin');
  }
  login(credentials:NgForm){
    return this.http.post(this.link, credentials);
  }
  getusername(){
    if(localStorage.getItem('nom')!=null){
      return localStorage.getItem('nom')+ ' ' +localStorage.getItem('prenom');
    }
    else{
      return '';
    }
  }
logout(){
  localStorage.removeItem('token');
    console.log(localStorage.getItem('token'));
    localStorage.removeItem('role');
    localStorage.removeItem('user_id');
    console.log(localStorage.getItem('role'));
    localStorage.removeItem('nom');
    console.log(localStorage.getItem('nom'));
    localStorage.removeItem('prenom');
    console.log(localStorage.getItem('prenom'));
  }
  logoutAuto(){
    setTimeout(() => {
      this.logout();
        this.router.navigate(['login']);
    }, 200);
  }

}
