import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParametreService } from '../parametre.service';
import { Parametre } from '../parametre';

@Component({
  selector: 'app-listeparametre',
  templateUrl: './listeparametre.component.html',
  styleUrls: ['./listeparametre.component.css']
})
export class ListeparametreComponent implements OnInit {
listeparametres : Parametre[]= [];
  constructor(
    private parametreService:ParametreService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.parametreService.listeParametre().subscribe(
      (response)=>{
        this.listeparametres=response;
      },
      (error)=>{
        alert("probleme d'acces à l'api");
      },
    );
  }
  redirectajouterparametre(){
     this.router.navigate(['ajouterparametre']);
  }
  redirectmodifierparametre(){
    this.router.navigate(['ajouterparametre']);
 }
}
