import { UpdateentiteComponent } from './updateentite/updateentite.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AjoutermobiliteComponent } from './ajoutermobilite/ajoutermobilite.component';
import { ListermobiliteComponent } from './listermobilite/listermobilite.component';
import { TableaudemobiliteComponent } from './tableaudemobilite/tableaudemobilite.component';
import { AjoutertypemobiliteComponent } from './ajoutertypemobilite/ajoutertypemobilite.component';
import { ListetypemobiliteComponent } from './listetypemobilite/listetypemobilite.component';
import { ModifiertypemobiliteComponent } from './modifiertypemobilite/modifiertypemobilite.component';
import { ModifiermobiliteComponent } from './modifiermobilite/modifiermobilite.component';
import { ListeparametreComponent } from './listeparametre/listeparametre.component';
import { AjouterparametreComponent } from './ajouterparametre/ajouterparametre.component';
import { ListevaleurComponent } from './listevaleur/listevaleur.component';
import { AjoutervaleurComponent } from './ajoutervaleur/ajoutervaleur.component';
import { EntitelisteComponent } from './entiteliste/entiteliste.component';
import { AjouterentiteComponent } from './ajouterentite/ajouterentite.component';
import { AjouterpersonneComponent } from './ajouterpersonne/ajouterpersonne.component';
import { ListerpersonneComponent } from './listerpersonne/listerpersonne.component';
import { DetailmobiliteComponent } from './detailmobilite/detailmobilite.component';
import { UpdatepersonneComponent } from './updatepersonne/updatepersonne.component';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './guard/login.guard';
import { MamobiliteComponent } from './mamobilite/mamobilite.component';
import { UpdateuserComponent } from './updateuser/updateuser.component';
import { UpdatemobiliteComponent } from './updatemobilite/updatemobilite.component';
import { SidebbarComponent } from './sidebbar/sidebbar.component';
import { ListemobiliteparentiteComponent } from './listemobiliteparentite/listemobiliteparentite.component';
import { TableaudemobilitedirecteurComponent } from './tableaudemobilitedirecteur/tableaudemobilitedirecteur.component';
import { MesmobiliteComponent } from './mesmobilite/mesmobilite.component';
import { UpdateUserPasswordComponent } from './update-user-password/update-user-password.component';
import { MobiliteavenirComponent } from './mobiliteavenir/mobiliteavenir.component';
import { MobiliteAllComponent } from './mobilite-all/mobilite-all.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
},
  {path:'login', component:LoginComponent},
  {path:'ajoutermobilite', component:AjoutermobiliteComponent},
  {path:'listemobilite',component:ListermobiliteComponent, canActivate:[LoginGuard]},
  {path:'tableaudemobilite',component:TableaudemobiliteComponent, canActivate:[LoginGuard]},
  {path:'mamobilite',component:MamobiliteComponent,canActivate:[LoginGuard]},
  {path:'modifiermobilite/:id',component:ModifiermobiliteComponent,canActivate:[LoginGuard]},
  {path:'listeparametre', component:ListeparametreComponent, canActivate:[LoginGuard]},
  {path:'ajouterparametre', component:AjouterparametreComponent,canActivate:[LoginGuard]},
  {path:'listevaleur', component:ListevaleurComponent, canActivate:[LoginGuard]},
  {path:'ajoutervaleur', component:AjoutervaleurComponent,canActivate:[LoginGuard]},
  {path:'modifiertypemobilite/:id', component:ModifiertypemobiliteComponent},
  {path:'listentite', component:EntitelisteComponent, canActivate:[LoginGuard]},
  {path:'ajouterentite', component:AjouterentiteComponent,canActivate:[LoginGuard]},
  {path:'ajouterpersonne',component:AjouterpersonneComponent, canActivate:[LoginGuard]},
  {path:'listerpersonne',component:ListerpersonneComponent, canActivate:[LoginGuard]},
  {path:'detailmobilite',component:DetailmobiliteComponent, canActivate:[LoginGuard]},
  {path:'modifierpersonne/:id', component:UpdatepersonneComponent,canActivate:[LoginGuard]},
  {path:'updateUser', component:UpdateuserComponent, canActivate:[LoginGuard]},
  {path:'sidebarr', component:SidebbarComponent},
  {path:'listemobiliteparentite', component:ListemobiliteparentiteComponent, canActivate:[LoginGuard]},
  {path: 'modifierentite/:id', component:UpdateentiteComponent, canActivate:[LoginGuard]},
  {path:'tableaumobilitedirecteur', component:TableaudemobilitedirecteurComponent, canActivate:[LoginGuard]},
  {path: '', component:LoginComponent},
  {path: 'mesmobilites', component:MesmobiliteComponent,canActivate:[LoginGuard] },
  { path: 'userUpadatePass', component:UpdateuserComponent,canActivate:[LoginGuard]},
  { path: 'changerMonPass', component:UpdateUserPasswordComponent ,canActivate:[LoginGuard]},
  { path: 'mobilitesAvenir', component:MobiliteavenirComponent ,canActivate:[LoginGuard]},
  { path: 'allmobilites', component:MobiliteAllComponent ,canActivate:[LoginGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
