
<div class="block full">
  <!-- All Products Title -->
  <div class="block-title">
      <div class="block-options pull-right">
          <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default" data-toggle="tooltip" title="Settings"><i class="fa fa-cog"></i></a>
      </div>
      <h2><strong>Liste</strong> des agents indisponibles</h2>

  </div>
  <table  id="datatable" class="row-border hover">
    <thead>
      <tr>
        <th class="text-left">Service</th>
      <th class="text-left">Matricule</th>
      <th class="text-left">Nom</th>
      <th class="text-left">Période</th>
      <th class="text-left" style="width: 10%;">Position</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mobilite of mobilites">
        <td class="text-left">{{ mobilite['service'] }}</td>
        <td class="text-left">{{mobilite['matricule'] }} </td>
         <td class="text-left">{{mobilite['nom'] }} {{mobilite['prenom'] }} </td>
        <td class="text-left"><strong>du :</strong> {{mobilite['datedebut'] | date: 'dd-MM-yyyy'}}  <strong> au :</strong>  {{mobilite['datefin'] | date: 'dd-MM-yyyy'}}</td>
        <td *ngIf="mobilite['libelle']=='Mission'" style="background-color: #479cd4;">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
        <td *ngIf="mobilite['libelle']=='Conge'" style="background-color:#b14b41">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
        <td *ngIf="mobilite['libelle']=='Autorisation absence'" style="background-color:#ea7967;">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
        <td *ngIf="mobilite['libelle']=='Atelier'" style="background-color: #91b5cc ;">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
      </tr>
    </tbody>
</table>
  <!-- END All Products Content -->
</div>







