import { Component, OnInit } from '@angular/core';
import { MobiliteService } from '../mobilite.service';
import { Mobilite } from '../mobilite';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-mobiliteavenir',
  templateUrl: './mobiliteavenir.component.html',
  styleUrls: ['./mobiliteavenir.component.css']
})
export class MobiliteavenirComponent implements OnInit {
  mobilites : Mobilite[]=[];

  mobilitesatistique: any;
  mobilite:any;
  dtOptions1: DataTables.Settings = {};
  bsModalRef: any;
  pipe = new DatePipe('en-US');
  constructor(
    config: NgbModalConfig,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private mobiliteService:MobiliteService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
      this.mobiliteService.mobiliteavenir().subscribe(
        (response)=>{
          setTimeout(()=>{
            $('#datatableexample').DataTable( {
              language: {
                processing:     "Traitement en cours...",
                search:         "Rechercher&nbsp;:",
                lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
                info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                infoPostFix:    "",
                loadingRecords: "Chargement en cours...",
                zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
                emptyTable:     "Aucune donnée disponible dans le tableau",
                paginate: {
                    first:      "Premier",
                    previous:   "Pr&eacute;c&eacute;dent",
                    next:       "Suivant",
                    last:       "Dernier"
                },
                aria: {
                    sortAscending:  ": activer pour trier la colonne par ordre croissant",
                    sortDescending: ": activer pour trier la colonne par ordre décroissant"
                }
            },
              pagingType: 'full_numbers',
              pageLength: 5,
              processing: true,
              lengthMenu : [5, 10, 25],
              order:[[1,"desc"]]
          } );
          }, 1);
          console.log(response);
          this.mobilites= response;
        }


      );
      this.mobiliteService.satistiqueMobilite().subscribe(
        (result)=>{
          console.log(result);
         this.mobilitesatistique=result;
              console.log(result);
        }
      )
  }
  detailmobilite(id:number){
    this.mobiliteService.getMobilitebyid(id).subscribe(
      (response)=>{
        this.mobilite =response;
        console.log(response)
        const initialState = {
          nom:response.nom,
          prenom : response.prenom,
          matricule: response.matricule,
          service: response.service,
          typemobilite: response.libelle,
          description:response.description,
          lieu: response.lieu,
          datedebut: this.pipe.transform(response.datedebut, 'dd-MM-yyyy'),
          datefin: this.pipe.transform(response.datefin, 'dd-MM-yyyy'),
        };
        this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
        this.bsModalRef.content.closeBtnName = 'Close';
      },
      (error)=>{
        alert("Probleme d'access aux api");
      }
    );
    }

}
@Component({
  selector: 'modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">Details de la Mobilite</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

         <span class="col-md-4 offset-md-4" > <strong>Matricule :</strong> </span>  <span class="col-md-6" >{{ matricule }} </span>
        <hr>
        <span class="col-md-4 offset-md-4" >  <strong>Nom :</strong> </span> <span class="col-md-6" >{{ nom }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Prenom :</strong> </span> <span class="col-md-4" >{{ prenom }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Service :</strong> </span> <span class="col-md-4" >{{ service}} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>En :</strong> </span> <span class="col-md-4" style="color:red;" >{{ typemobilite }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Du :</strong> </span> <span class="col-md-4" >{{ datedebut }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Au :</strong> </span> <span class="col-md-4" >{{ datefin }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Lieu :</strong> </span> <span class="col-md-4" >{{ lieu }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Description :</strong> </span> <span class="col-md-4" > {{ description }} </span> <br>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Fermer</button>
    </div>

  `
})
export class ModalContentComponent implements OnInit {
  title: string='';
  description: string='';
  nom: string='';
  prenom: string='';
  typemobilite: any;
  matricule: string='';
  datedebut: any;
  datefin: any;
  service: string="";
  lieu: string='';
  list: any[] = [];

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.list.push('PROFIT!!!');

 }
}
