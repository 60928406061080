import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Entite } from 'e2e/src/entite';
import { MobiliteService } from '../mobilite.service';
import { PersonneService } from '../personne.service';
import { EntiteService } from '../entite.service';
import { Personne } from '../personne';
import { NgForm } from '@angular/forms';
import { ValeurService } from '../valeur.service';
import { Valeur } from '../valeur';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Mobilite } from '../mobilite';
import { frLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
defineLocale('fr', frLocale);
@Component({
  selector: 'app-ajoutermobilite',
  templateUrl: './ajoutermobilite.component.html',
  styleUrls: ['./ajoutermobilite.component.css']
})
export class AjoutermobiliteComponent implements OnInit {
entites:Valeur[]=[];
typeconge:Valeur[]=[];
personnes:Personne[]=[];
today: any;
minDate: any;
entite_id:any;
mobilitePersonne:Mobilite =  {id: 1, typemobilite:1,datedebut:"", datefin:'', matricule:'',nom:'', prenom:'',service:'',description:'',lieu:'', libelle:'', entiteparent:'',typeconge:''};
newdatedebut:string='';
newdatefin:string='';
flag:number=0;
flag2:number=0;
minDate1:any;

  constructor(
    private mobiliteService:MobiliteService,
    private personneService:PersonneService,
    private valeurService:ValeurService,
    private router:Router,
    private ActivatedRoute:ActivatedRoute,
    private flashMessage: FlashMessagesService,
    private localeService: BsLocaleService
  ) { }

  ngOnInit(): void {
    this.entite_id=localStorage.getItem('entite');
    this.today = new Date();
   this.minDate1 = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() , 2);
   this.minDate = new Date(this.today.getFullYear(), this.today.getMonth(), 2);
   this.localeService.use('fr');
   this.personneService.listePersonneparentite(this.entite_id).subscribe(
      (response)=>{
        this.personnes=response;
      },
    );
    this.valeurService.listeValeur().subscribe(
      (retour)=>{
        this.entites=retour
      }
    );
    this.valeurService.listetypeconge().subscribe(
      (typeconge)=>{
        this.typeconge=typeconge;
      }
    );
  }
  ajoutermobilite(formulaire:NgForm){

    this.mobiliteService.addMobilite(formulaire.value).subscribe(
      (result)=>{
        this.flashMessage.show('Votre mobilite a été enregistre avec success', {
          cssClass: 'custom-success', timeout: 2000 });
        this.router.navigate(['listemobiliteparentite']);

      },
      (error)=>{
        alert("probleme d'access aux API");
      }
    );
  }

  redirectback(){
    this.router.navigate(['listemobiliteparentite']);
  }
public ispossible(id:number){
this.mobiliteService.mobiliteActive(id).subscribe(
  (response)=>{
    this.mobilitePersonne=response;
    console.log(this.mobilitePersonne.datefin);
    const datefin= new Date(this.mobilitePersonne.datefin)
    const datedebut = new Date(this.mobilitePersonne.datedebut);
    const newdatedebut= new Date(this.newdatedebut);
    console.log(datefin);
  console.log(this.newdatedebut);

  const newdatefin= new Date(this.newdatefin);
    if(newdatedebut > newdatefin){
      this.flag2=0;
      alert("La date debut ne doit pas être supérieur à date fin !!!")
    }
    else{
      this.flag2=1;
    }
    if(datefin >= newdatedebut ){
      console.log('cool');
      if(!(newdatedebut<datedebut)){
        this.flag=0;
        alert("L'agent est déjà enregistré indisponible pour cette période !!!")
      }

    }
    else{
       this.flag=1
    }


  }
)
}
changeColor(){
  console.log('okok');
}
test($event:any){
  console.log("cool");
}
}
