<div class="col-md-10">
  <!-- Basic Form Elements Block -->
  <div class="block">
      <!-- Basic Form Elements Title -->
      <div class="block-title">
          <div class="block-options pull-right">

          </div>
          <h2><strong>Enregistrer ma mobilite</strong></h2>
      </div>
      <!-- END Form Elements Title -->
      <flash-messages></flash-messages>
      <!-- Basic Form Elements Content -->
      <form #formmobilite="ngForm"class="form-horizontal form-bordered">

            <input [(ngModel)]="user" #inputPersonne="ngModel"  type="hidden" name="personne">

        <div class="form-group">
            <label class="col-md-3 control-label" for="example-select">Type de mobilite</label>
            <div class="col-md-6">
              <select name="typemobilite" #inputtypemobilite="ngModel" required ngModel id="" class="form-control" style="width: 100%;">
                <option  *ngFor="let entite of entites" [value]="entite.id">{{ entite.libelle }}</option>
            </select>
            </div>
        </div>
        <div class="form-group" *ngIf="inputtypemobilite.value==2">
          <label class="col-md-3 control-label" for="example-select">Type conge</label>
          <div class="col-md-6">
            <select name="typeconge" #inputtypeconge="ngModel" required ngModel id=""  class="form-control" style="width: 100%;">
              <option  *ngFor="let typeconge of typeconge" [value]="typeconge.id">{{ typeconge.libelle }}</option>
          </select>
          </div>
      </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="example-datepicker">Date debut</label>
          <div class="col-md-6">
              <input type="text" required [(ngModel)]=newdatedebut  [minDate]="minDate" name="datedebut" bsDatepicker class="form-control" data-date-format="dd/mm/yy" placeholder="mm/dd/yy" (ngModelChange)="ispossible(inputPersonne.value)">
          </div>
      </div>
      <div class="form-group">
        <label class="col-md-3 control-label" for="example-datepicker">Date fin</label>
        <div class="col-md-6">
            <input type="text" required  [(ngModel)]=newdatefin [minDate]="minDate1" name="datefin" bsDatepicker class="form-control"  data-date-format="dd/mm/yy" placeholder="mm/dd/yy" (ngModelChange)="ispossible(inputPersonne.value)">
        </div>
    </div>
    <div class="form-group" *ngIf="inputtypemobilite.value==34||inputtypemobilite.value==35">
      <label class="col-md-3 control-label" for="Lieu">Lieu de l'activite</label>
      <div class="col-md-6">
          <input type="text"  ngModel name="lieu" class="form-control" placeholder="Lieu">
      </div>
    </div>
        <div class="form-group" *ngIf="inputtypemobilite.value==34||inputtypemobilite.value==35">
          <label class="col-md-3 control-label" for="description">Description</label>
          <div class="col-md-6">
              <textarea id="description"  ngModel name="description" rows="7" class="form-control" placeholder="Description de l'activité."></textarea>
          </div>
      </div>
          <div class="form-group form-actions">
              <div class="col-md-9 col-md-offset-3">
                 <button [disabled]="formmobilite.invalid || (flag == 0)|| (flag2 == 0)" (click)= "ajoutermobilite(formmobilite)" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Enregistrer</button>
                 <button class="btn btn-sm btn-warning mr2" style="margin-left: 200px;" (click)="redirectback()" ><i class="fa fa-repeat"></i> Annuler</button>
              </div>
          </div>
      </form>
      <!-- END Basic Form Elements Content -->
  </div>
  <!-- END Basic Form Elements Block -->
</div>

