import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AjoutermobiliteComponent } from './ajoutermobilite/ajoutermobilite.component';
import { SidebbarComponent } from './sidebbar/sidebbar.component';
import { ListermobiliteComponent } from './listermobilite/listermobilite.component';
import { TableaudemobiliteComponent } from './tableaudemobilite/tableaudemobilite.component';
import { ListetypemobiliteComponent } from './listetypemobilite/listetypemobilite.component';
import { AjoutertypemobiliteComponent } from './ajoutertypemobilite/ajoutertypemobilite.component';
import { ModifiertypemobiliteComponent } from './modifiertypemobilite/modifiertypemobilite.component';
import { FormsModule, NgForm } from '@angular/forms';
import { ModifiermobiliteComponent } from './modifiermobilite/modifiermobilite.component';
import { HttpClientModule } from '@angular/common/http';
import { ListeparametreComponent } from './listeparametre/listeparametre.component';
import { AjouterparametreComponent } from './ajouterparametre/ajouterparametre.component';
import { AjoutervaleurComponent } from './ajoutervaleur/ajoutervaleur.component';
import { ListevaleurComponent } from './listevaleur/listevaleur.component';
import { EntitelisteComponent } from './entiteliste/entiteliste.component';
import { AjouterentiteComponent } from './ajouterentite/ajouterentite.component';
import { AjouterpersonneComponent } from './ajouterpersonne/ajouterpersonne.component';
import { ListerpersonneComponent } from './listerpersonne/listerpersonne.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DetailmobiliteComponent } from './detailmobilite/detailmobilite.component';
import { UpdatepersonneComponent } from './updatepersonne/updatepersonne.component';
import { LoginComponent } from './login/login.component';
import { LonginInterceptorProvider } from './intercepeters/logininterceptor';
import { LoginGuard } from './guard/login.guard';
import { HeaderComponent } from './header/header.component';
import { LogoutGuard } from './guard/logout.guard';
import { MamobiliteComponent } from './mamobilite/mamobilite.component';
import { DataTablesModule } from 'angular-datatables';
import { UpdateuserComponent } from './updateuser/updateuser.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UpdatemobiliteComponent } from './updatemobilite/updatemobilite.component';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { ListemobiliteparentiteComponent } from './listemobiliteparentite/listemobiliteparentite.component';
import { UpdateentiteComponent } from './updateentite/updateentite.component';
import { RouterModule } from '@angular/router';
import { TableaudemobilitedirecteurComponent } from './tableaudemobilitedirecteur/tableaudemobilitedirecteur.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MesmobiliteComponent } from './mesmobilite/mesmobilite.component';
import { UpdateUserPasswordComponent } from './update-user-password/update-user-password.component';
import { MobiliteavenirComponent } from './mobiliteavenir/mobiliteavenir.component';
import { MobiliteAllComponent } from './mobilite-all/mobilite-all.component';

@NgModule({
  declarations: [
    AppComponent,
    AjoutermobiliteComponent,
    SidebbarComponent,
    ListermobiliteComponent,
    TableaudemobiliteComponent,
    ListetypemobiliteComponent,
    AjoutertypemobiliteComponent,
    ModifiertypemobiliteComponent,
    ModifiermobiliteComponent,
    ListeparametreComponent,
    AjouterparametreComponent,
    AjoutervaleurComponent,
    ListevaleurComponent,
    EntitelisteComponent,
    AjouterentiteComponent,
    AjouterpersonneComponent,
    ListerpersonneComponent,
    DetailmobiliteComponent,
    UpdatepersonneComponent,
    LoginComponent,
    HeaderComponent,
    MamobiliteComponent,
    UpdateuserComponent,
    UpdatemobiliteComponent,
    ListemobiliteparentiteComponent,
    UpdateentiteComponent,
    TableaudemobilitedirecteurComponent,
    MesmobiliteComponent,
    UpdateUserPasswordComponent,
    MobiliteavenirComponent,
    MobiliteAllComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    DataTablesModule,
    ModalModule.forRoot(),
    FlashMessagesModule.forRoot(),

  ],
  providers: [
    LonginInterceptorProvider,
    LoginGuard,
    LogoutGuard,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
