import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Valeur } from './valeur';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ValeurService {

  constructor(
    private http:HttpClient
  ) { }
  apiUrl = environment.apiUrl;
  link=`${this.apiUrl}/valeur`;
    addValeur(valeur:Valeur):Observable<any>
    {
        return this.http.post(this.link,valeur);
    }
    listeValeur():Observable<Valeur[]>{
      return this.http.get<Valeur[]>(this.link);
    }
    getValeurbyid(id:number):Observable<Valeur>{
      return this.http.get<Valeur>(this.link+`/${id}`);
    }
    updateValeur(id:number, valeur:Valeur):Observable<any>{
      return this.http.put(this.http+`/${id}`,valeur);
    }
    listeTypeMobilite():Observable<Valeur[]>{
      return this.http.get<Valeur[]>(this.link);
     }
     listetypeconge():Observable<Valeur[]>{
       return this.http.get<Valeur[]>(`${this.apiUrl}/typeconge`);
     }
}
