import { Component, OnInit } from '@angular/core';
import { ValeurService } from '../valeur.service';
import { Entite } from '../entite';
import { Valeur } from '../valeur';
import { MobiliteService } from '../mobilite.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { FlashMessagesModule, FlashMessagesService } from 'angular2-flash-messages';
import { AuthentificationService } from '../authentification.service';
import { Mobilite } from '../mobilite';
import { frLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
defineLocale('fr', frLocale);

@Component({
  selector: 'app-mamobilite',
  templateUrl: './mamobilite.component.html',
  styleUrls: ['./mamobilite.component.css']
})
export class MamobiliteComponent implements OnInit {
  entites: Valeur[]=[];
  typeconge:Valeur[]=[];
  user : any;
  today: any;
  minDate: any;
  minDate1: any;
mobilitePersonne:Mobilite =  {id: 1, typemobilite:1,datedebut:"", datefin:'', matricule:'',nom:'', prenom:'',service:'',description:'',lieu:'', libelle:'',entiteparent:'',typeconge:''};
newdatedebut:string='';
newdatefin:string='';
flag:number=0;
flag2:number=0;
  constructor(
    private valeurService:ValeurService,
    private mobiliteService:MobiliteService,
    private router:Router,
    private authentificationService:AuthentificationService,
    private flashMessage: FlashMessagesService,
    private localeService: BsLocaleService
  ) {
  }

  ngOnInit(): void {
    this.today = new Date();
    this.minDate1 = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() , 2);
    this.minDate = new Date(this.today.getFullYear(), this.today.getMonth(), 2);
    this.localeService.use('fr');
    this.valeurService.listeValeur().subscribe(
      (retour)=>{
        this.entites=retour;
      }
    );
    this.user = localStorage.getItem('user_id');
    const user= this.user
    this.valeurService.listetypeconge().subscribe(
      (typeconge)=>{
        this.typeconge=typeconge;
      }
    );
  }
  ajoutermobilite(formulaire:NgForm){
 console.log(formulaire.value);
    this.mobiliteService.addMobilite(formulaire.value).subscribe(
      (result)=>{

          this.router.navigate(['mesmobilites']);
          this.flashMessage.show('Votre mobilite a été enregistre avec success', {
            cssClass: 'custom-success', timeout: 15000 });

    },
    (error)=>{
        alert("probleme d'access aux API");
     }
   );
  }
  redirectback(){
    this.router.navigate(['mesmobilites']);
  }

  ispossible(id:number){
    this.mobiliteService.mobiliteActive(id).subscribe(
      (response)=>{
        this.mobilitePersonne=response;
        console.log(this.mobilitePersonne.datefin);
        const datefin= new Date(this.mobilitePersonne.datefin)
        const datedebut = new Date(this.mobilitePersonne.datedebut);
        const newdatedebut= new Date(this.newdatedebut);
        console.log(datefin);
      console.log(this.newdatedebut);

      const newdatefin= new Date(this.newdatefin);
        if(newdatedebut > newdatefin){
          this.flag2=0;
          alert("La date debut ne doit pas être supérieur à date fin !!!")
        }
        else{
          this.flag2=1;
        }
        if(datefin >= newdatedebut ){
          console.log('cool');
          if(!(newdatedebut<datedebut)){
            this.flag=0;
            alert("Vous êtes déjà enregistré indisponible pour cette période !!!")
          }

        }
        else{
           this.flag=1
        }


      }
    )
    }
}
