<div class="col-md-8 col-md-offset-2" style="margin-top: 15%;">
  <!-- Basic Form Elements Block -->
  <div class="block">
      <!-- Basic Form Elements Title -->
      <div class="block-title">
          <div class="block-options pull-right">
              <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default toggle-bordered enable-tooltip" data-toggle="button" title="Toggles .form-bordered class"></a>
          </div>
          <h2><strong>Veillez changer votre mot de passe</strong></h2>
      </div>

      <!-- END Form Elements Title -->

      <!-- Basic Form Elements Content -->
      <form #formvaleur="ngForm" class="form-horizontal form-bordered">

        <div class="form-group">
          <label class="col-md-4 control-label" for="passwordssss">Nouveau mot de password</label>
          <div class="col-md-6">
            <input type="password"
             minlength="4"
            name="passwordssss"
            #inputPassword="ngModel"
            [(ngModel)]=user.password
            ngModel
            class="form-control" >
            <div class="alert alert-danger" *ngIf="inputPassword.invalid && (inputPassword.dirty || inputPassword.touched)" >
              Taper un mot de passe de 4 caractere au minimum
           </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="password_confirmation">Taper le nouveau mot de passe</label>
          <div class="col-md-6">
            <input type="password"
            ngModel required
             name="password_confirmation"
             #inputPasswordConfirm="ngModel"
             class="form-control" >
           <div class="alert alert-danger" *ngIf="(inputPasswordConfirm.dirty || inputPasswordConfirm.touched)&&(inputPassword.value != inputPasswordConfirm.value)" >
                  Le mots de passe différents. Veillez ressaisir les mots de passe
          </div>
          </div>
        </div>
        <div class="form-group form-actions" >
              <div class="col-md-9 col-md-offset-4">
                <button [disabled]="(inputPassword.value != inputPasswordConfirm.value)" (click)= "updateuserpass()" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Enregistrer</button>
              </div>
          </div>
      </form>
      <!-- END Basic Form Elements Content -->
  </div>
  <!-- END Basic Form Elements Block -->
</div>

