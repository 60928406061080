<div id="page-wrapper">

  <div id="page-container" class="sidebar-partial sidebar-visible-lg sidebar-no-animations">
      <!-- Alternative Sidebar -->

      <app-sidebbar></app-sidebbar>

      <div id="main-container" >

        <app-header></app-header>
          <div id="page-content" style="min-height:auto !important;">

              <div class="row">
                <router-outlet></router-outlet>
              </div>
              <!-- END Dashboard 2 Content -->
          </div>
          <!-- END Page Content -->

          <!-- Footer -->
          <footer class="clearfix">
              <div class="pull-right">
                  Réalisé par<a href="https:www.anptic.gov.bf" style="color: black;" target="_blank"> ANPTIC</a>
              </div>
              <div class="pull-left">
                  <span>Application de suivi de la mobilité des agents </span>
              </div>
          </footer>
          <!-- END Footer -->
      </div>
      <!-- END Main Container -->
  </div>
  <!-- END Page Container -->

 <!-- Scroll to top link, initialized in js/app.js - scrollToTop() -->

