import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Mobilite } from '../mobilite';
import { MobiliteService } from '../mobilite.service';
import { ModalContentComponent } from '../tableaudemobilite/tableaudemobilite.component';

@Component({
  selector: 'app-mobilite-all',
  templateUrl: './mobilite-all.component.html',
  styleUrls: ['./mobilite-all.component.css']
})
export class MobiliteAllComponent implements OnInit {
  mobilites:Mobilite[]=[];
  mobilite:any;
  bsModalRef: any;
  modalRef: any;
  pipe = new DatePipe('en-US');
  constructor(
    private mobiliteService:MobiliteService,
    private router:Router,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.mobiliteService.allmobilite().subscribe(
      (response)=>{
        setTimeout(()=>{
              $('#datatable').DataTable( {
                  language: {
                    processing:     "Traitement en cours...",
                    search:         "Rechercher&nbsp;:",
                    lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
                    info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                    infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                    infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                    infoPostFix:    "",
                    loadingRecords: "Chargement en cours...",
                    zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
                    emptyTable:     "Aucune donnée disponible dans le tableau",
                    paginate: {
                        first:      "Premier",
                        previous:   "Pr&eacute;c&eacute;dent",
                        next:       "Suivant",
                        last:       "Dernier"
                    },
                    aria: {
                        sortAscending:  ": activer pour trier la colonne par ordre croissant",
                        sortDescending: ": activer pour trier la colonne par ordre décroissant"
                    }
                },
                  pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  lengthMenu : [5, 10, 25],
                });
              }, 1);
                console.log(response);
                this.mobilites = response;
              }
    )
  }
  detailmobilite(id:number){
    this.mobiliteService.getMobilitebyid(id).subscribe(
      (response)=>{
        this.mobilite =response;
        console.log(response)
        const initialState = {
          nom:response.nom,
          prenom : response.prenom,
          matricule: response.matricule,
          service: response.service,
          typemobilite: response.libelle,
          description:response.description,
          lieu: response.lieu,
          datedebut: this.pipe.transform(response.datedebut, 'dd-MM-yyyy'),
          datefin: this.pipe.transform(response.datefin, 'dd-MM-yyyy'),
        };
        this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
        this.bsModalRef.content.closeBtnName = 'Close';
      },
      (error)=>{
        alert("Probleme d'access aux api");
      }
    );

}
}


