import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import { AuthentificationService } from '../authentification.service';

export class LoginInterceptor implements HttpInterceptor{
  err:any

  intercept(req: HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>>{
    const token= localStorage.getItem('token');
    if(token){
      const reqclone= req.clone({
        setHeaders: {
            Authorization: "Bearer" + token
        }
    });
      return next.handle(reqclone)
     .pipe(
       catchError(err => {
        console.log(err);
        if (err instanceof HttpErrorResponse) {
            console.log(err.statusText);
            if (err.status === 401|| err.status === 403) {
              localStorage.clear();
              window.location.href = "/login";
            }
        }
        const error = (err && err.error && err.error.message) || err.statusText;
        console.error(err);
        return throwError(error);
    }));
    }else{
      return next.handle(req);
    }

  }

}
export const LonginInterceptorProvider= {
  provide: HTTP_INTERCEPTORS,
  useClass: LoginInterceptor,
  multi: true
}
