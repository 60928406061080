import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Mobilite } from './mobilite';
import { Statmobilite } from './statmobilite';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MobiliteService {

  constructor(
   private http:HttpClient,
  ) { }
  apiUrl = environment.apiUrl;
  link=`${this.apiUrl}/mobilite`;
    addMobilite(mobilite:Mobilite):Observable<any>
    {
        return this.http.post(this.link,mobilite);
    }
    listeMobilite():Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(this.link);
    }
    getMobilitebyid(id:number):Observable<Mobilite>{
      return this.http.get<Mobilite>(this.link+`/${id}`);
    }
    updateMobilite(id:number, mobilite:Mobilite):Observable<any>{

      return this.http.put(`${this.apiUrl}/updatemobilite`+`/${id}`,mobilite);
    }
    satistiqueMobilite():Observable<Statmobilite>{
      return this.http.get<Statmobilite>(`${this.apiUrl}/mobilitestatistique`);
    }
    mobiliteparentites(id:number):Observable<Mobilite[]>{
        return this.http.get<Mobilite[]>(`${this.apiUrl}/mobilibiteparentite`+`/${id}`);
    }
    satistiqueMobiliteParEntite(id:number):Observable<Statmobilite>{
      return this.http.get<Statmobilite>(`${this.apiUrl}/mobilitesatistiquepareentite`+`/${id}`);
    }
    deletemobilite(id:number):Observable<any>{
      return this.http.delete(this.link+`/${id}`);
    }
    mobiliteActive(id:number):Observable<Mobilite>{
      return this.http.get<Mobilite>(`${this.apiUrl}/mobilitePersonne`+`/${id}`);
    }
    public getmesmobilites(id:number):Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/mesmobilites/`+`${id}`);
    }
    public mobiliteavenir():Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/mobilitesAvenir`);
    }
    public getconges(id:number):Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listecongeparEntite/`+`${id}`);
    }
    public getautorisations(id:number):Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listeautorisationparEntite/`+`${id}`);
    }
    public getmission(id:number):Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listemissionparEntite/`+`${id}`);
    }
    public getatelier(id:number):Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listeatelierparEntite/`+`${id}`);
    }
    public listerconge():Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listeconge`);
    }
    public listeratelier():Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listeatelier`);
    }
    public listermission():Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listemission`);
    }
    public listeautorisation():Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/listeautorisation`);
    }
    public allmobilite():Observable<Mobilite[]>{
      return this.http.get<Mobilite[]>(`${this.apiUrl}/allmobilites`);
    }
}
