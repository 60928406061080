<div class="col-md-10">
  <!-- Basic Form Elements Block -->
  <div class="block">
      <!-- Basic Form Elements Title -->
      <div class="block-title">
          <div class="block-options pull-right">
              <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default toggle-bordered enable-tooltip" data-toggle="button" title="Toggles .form-bordered class"></a>
          </div>
          <h2><strong>Modifier la Mobilite</strong></h2>
      </div>
      <form #formmobilite="ngForm"class="form-horizontal form-bordered">
        <div class="form-group">
          <label class="col-md-3 control-label" for="email">Agent</label>
          <div class="col-md-6">
            <input type="text"  name="personne" class="form-control"   placeholder="{{ mobilite.matricule}} {{ mobilite.nom}} {{ mobilite.prenom}} "disabled>
          </div>
        </div>
          <div class="form-group">
            <label class="col-md-3 control-label" for="example-select">Type de mobilite</label>
            <div class="col-md-6">
              <select  name="typemobilite" [(ngModel)]="mobilite.typemobilite" class="form-control" style="width: 100%;">
                <option  *ngFor="let valeur of valeurs" [value]="valeur.id">{{ valeur.libelle }}</option>
            </select>
            </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="example-datepicker">Date debut</label>
          <div class="col-md-6">
              <input type="text"  [(ngModel)]="mobilite.datedebut" [minDate]="minDate" name="datedebut" bsDatepicker class="form-control" >
          </div>
      </div>
      <div class="form-group">

        <label class="col-md-3 control-label" for="example-datepicker">Date fin</label>
        <div class="col-md-6">
            <input type="text"  [(ngModel)]="mobilite.datefin" name="datefin"  [minDate]="minDate1" bsDatepicker  #dp="bsDatepicker" class="form-control" data-date-format="mm/dd/yy" placeholder="mm/dd/yy">
        </div>
    </div>
    <div class="form-group" *ngIf="mobilite.typemobilite==34||mobilite.typemobilite==35">
      <label class="col-md-3 control-label" for="Lieu">Lieu de l'activite</label>
      <div class="col-md-6">
          <input type="text"  [(ngModel)]="mobilite.lieu" name="lieu" class="form-control" placeholder="Lieu">
      </div>
  </div>

    <div class="form-group" *ngIf="mobilite.typemobilite==34||mobilite.typemobilite==35">
      <label class="col-md-3 control-label" for="description">Description</label>
      <div class="col-md-6">
          <textarea id="description"  [(ngModel)]="mobilite.description" name="description" rows="7" class="form-control" placeholder="Description de l'activité."></textarea>
      </div>
  </div>
          <div class="form-group form-actions">
              <div class="col-md-9 col-md-offset-3">
                 <button [disabled]="formmobilite.invalid" (click)= "modifiermobilite()" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Enregistrer</button>
                 <button class="btn btn-sm btn-warning mr2" style="margin-left: 200px;" (click)="redirectback()" ><i class="fa fa-repeat"></i> Annuler</button>
              </div>
          </div>
      </form>
      <!-- END Basic Form Elements Content -->
  </div>
  <!-- END Basic Form Elements Block -->
</div>
