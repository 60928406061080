import { Component, OnInit } from '@angular/core';
import { PersonneService } from '../personne.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Personne } from '../personne';
import { EntiteService } from '../entite.service';
import { Entite } from '../../../e2e/src/entite';

@Component({
  selector: 'app-updatepersonne',
  templateUrl: './updatepersonne.component.html',
  styleUrls: ['./updatepersonne.component.css']
})
export class UpdatepersonneComponent implements OnInit {
personne : Personne= {id:1, nom:'sanou', prenom:'stephane' , matricule: '2345',entite_id: 1, email:'soulesanou@gmai.com', password:'asze', role:''};
 entites: Entite[]=[];
 id:number=0;
 role=['user','dg','admin','manager' ,'sg'];
constructor(
    private personneService:PersonneService,
    private router : Router,
    private activatedRoute:ActivatedRoute,
    private entiteService:EntiteService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params)=>{
        this.personneService.getPersonnebyid(params.id).subscribe(
          (response)=>{
            console.log(response);
            this.personne=response;
            this.id=params.id;
      }
        );

      }
    );
    this.entiteService.listeEntite().subscribe(
      (entites)=>{
        this.entites=entites;
      },
      (error)=>{
        alert("probleme d'aaces aux API");
      }
    );
  }
  modifierpersonne(){
        this.personneService.updatePersonne(this.id,this.personne).subscribe(
      (result)=>{
        this.router.navigate(['listerpersonne']);
      },
      (error)=>{
        alert("probleme d'acces aux API");
      }
);
  }
  redirectback(){
    this.router.navigate(['listerpersonne']);
  }
}
