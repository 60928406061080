<div style="background-color: #479cd4; width: 100%; height: 100%; position: absolute; padding: 5% 0 0 2%; z-index: 9999; background-size: cover; ">


<div id="connexion"  style="background-image:url(/assets/img/Mobil-VF.jpg);z-index: 1; width: 75%; height: 75%; margin: auto; position:  absolute; background-size:cover; background-repeat: no-repeat;">

  <div  class="col-md-5 col-md-offset-1" style="margin-top: 250px;">


    <div class="block">
        <!-- Basic Form Elements Title -->
        <div class="block-title">
            <div class="block-options pull-right">
                <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default toggle-bordered enable-tooltip" data-toggle="button" title="Toggles .form-bordered class"></a>
            </div>
            <flash-messages></flash-messages>
            <h2><strong></strong> <img width="100px" src="assets/img/logokey.jpg" alt=""> </h2>
        </div>
        <form #loginform="ngForm" class="form-horizontal form-bordered">

          <div class="form-group">
            <label class="col-md-3 control-label" for="email">Login</label>
            <div class="col-md-6">
              <input type="text"
              ngModel
              email required
              #inputEmail="ngModel"
              name="email" class="form-control" >
              <div class="alert alert-danger" *ngIf="inputEmail.invalid && inputEmail.touched" >
                 Ceci n'est pas une adresse email
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label" for="assword">Mot de passe</label>
            <div class="col-md-6">
              <input type="password" ngModel required name="password" class="form-control" >
            </div>
          </div>

          <div class="form-group form-actions">
                <div class="col-md-9 col-md-offset-3">
                  <button [disabled]="loginform.invalid" (click)= "login(loginform.value)" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Se connecter</button>
                </div>
            </div>
        </form>
        <!-- END Basic Form Elements Content -->
    </div>
    <!-- END Basic Form Elements Block -->
  </div>
  </div>
</div>



