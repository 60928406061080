
<div class="block full">
  <div class="block-title">
      <div class="block-options pull-right">
          <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default" data-toggle="tooltip" title="Settings"><i class="fa fa-cog"></i></a>
      </div>
      <h2><strong>Mes mobilites signalées</strong></h2>
      <button class="btn btn-success" (click)="redirectajoutermobilite()" ><span><i class="fa fa-plus">Ajouter</i></span></button>
  </div>
  <flash-messages></flash-messages>
  <table id="datatableexample" class="row-border hover">
      <thead>
        <tr>
         <th class="text-center">Service</th>
        <th class="text-center">Matricule</th>
        <th class="text-center">Nom</th>
        <th class="text-center">Prenom</th>
        <th class="text-center">Date debut</th>
        <th class="text-center">Date fin</th>
        <th class="text-center">Position</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mobilite of mobilites">

          <td class="text-center">{{ mobilite['service'] }}</td>
          <td class="text-center" >{{mobilite['matricule'] }} </td>
          <td class="text-center">{{mobilite['nom'] }}  </td>
          <td class="text-center">{{mobilite['prenom'] }} </td>
          <td class="text-center">{{mobilite['datedebut'] | date: 'dd-MM-yyyy' }} </td>
          <td class="text-center">{{mobilite['datefin']  | date: 'dd-MM-yyyy'}} </td>
          <td class="text-center">{{mobilite['libelle'] }} </td>
          <td>
            <button class="btn btn-primary" (click)="redirectmodifiermobilite(mobilite['id'])" ><i class="fa fa-pencil"></i></button>
            <button class="btn btn-danger" (click)="openModal(confirm, mobilite['id'])" ><i class="fa fa-times"></i></button>
          </td>
        </tr>
      </tbody>
  </table>
</div>
<ng-template #confirm>
  <div class="modal-body text-center">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Confirmez l'annulation de l'indisponibilite</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"></button>
    <p>Voulez vous annuler cette mobilite?</p>
    <button type="button" class="btn btn-danger" (click)="supprimer()" >Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
  </div>
  </div>

</ng-template>


