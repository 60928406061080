<div class="col-md-10">
  <!-- Basic Form Elements Block -->
  <div class="block">
      <!-- Basic Form Elements Title -->
      <div class="block-title">
          <div class="block-options pull-right">
              <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default toggle-bordered enable-tooltip" data-toggle="button" title="Toggles .form-bordered class"></a>
          </div>
          <h2><strong>Ajouter un utilisateur</strong></h2>
      </div>

      <!-- END Form Elements Title -->

      <!-- Basic Form Elements Content -->
      <form #formvaleur="ngForm" class="form-horizontal form-bordered">
<!-- <div *ngIf="name.invalid && (name.dirty || name.touched)"
    class="alert alert-danger">

  <div *ngIf="name.errors.required">
    Name is required.
  </div>
  <div *ngIf="name.errors.minlength">
    Name must be at least 4 characters long.
  </div>
  <div *ngIf="name.errors.forbiddenName">
    Name cannot be Bob.
  </div>

</div> -->
        <div class="form-group">
          <label class="col-md-3 control-label" for="matricule">Matricule</label>
          <div class="col-md-6">
            <input type="text"

                ngModel
                required minlength="4"
                name="matricule"
                #inputMatricule="ngModel"
                class="form-control" >
                <div class="alert alert-danger" *ngIf="inputMatricule.invalid && inputMatricule.touched" >
                  Le champ matricule est un champ requis
               </div>

          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="nom">Nom</label>
          <div class="col-md-6">
            <input type="text"
                  ngModel
                  required
                  name="nom"
                  #inputNom="ngModel"
                  class="form-control" >
                  <div class="alert alert-danger" *ngIf="inputNom.invalid && inputNom.touched" >
                    Le champ prenom est un champ requis
                 </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="prenom">Prenom</label>
          <div class="col-md-6">
            <input type="text" ngModel
            name="prenom"
            required
            #inputPrenom="ngModel"
            class="form-control" >
            <div class="alert alert-danger" *ngIf="inputPrenom.invalid && inputPrenom.touched" >
              Le champ nom est un champ requis
           </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="email">Email</label>
          <div class="col-md-6">
            <input type="text"
            ngModel
            email required
            name="email"
            #inputEmail="ngModel"
            class="form-control" >
            <div class="alert alert-danger" *ngIf="inputEmail.invalid && inputEmail.touched" >
              Ceci n'est pas une adresse email
           </div>
          </div>

        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="email">Password</label>
          <div class="col-md-6">
            <input type="text" ngModel  name="password" class="form-control"  value="anptic1235" placeholder="anptic12345" disabled>
            <!-- <input type="text" ngModel  name="password_confirmation" class="form-control"  value="anptic1235" hidden > -->
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="example-chosen">Service</label>
            <div class="col-md-6">
              <select name="entite_id" class="form-control" ngModel id="" style="width: 100%;">
                <option  *ngFor="let entite of entites" [value]="entite.id">{{ entite.libelle }}</option>
            </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label" for="example-chosen">Role</label>
              <div class="col-md-6">
                <select name="role" class="form-control" ngModel  style="width: 100%;">
                  <option value="dg" >DG</option>
                  <option value="sg" >SG</option>
                  <option value="user" >User</option>
                  <option value="admin" >Administrateur</option>
                  <option value="manager" >Manager</option>

              </select>
              </div>
            </div>
        <div class="form-group form-actions">
              <div class="col-md-9 col-md-offset-3">
                <button [disabled]="formvaleur.invalid" (click)= "ajouterpersonne(formvaleur)" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Enregistrer</button>
                <button class="btn btn-sm btn-warning mr2" style="margin-left: 200px;" (click)="redirectback()" ><i class="fa fa-repeat"></i> Annuler</button>
              </div>
          </div>
      </form>
      <!-- END Basic Form Elements Content -->
</div>
  <!-- END Basic Form Elements Block -->
</div>

