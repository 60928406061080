import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Entite } from '../../e2e/src/entite';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class EntiteService {

  constructor(
    private http:HttpClient
  ) { }
  apiUrl = environment.apiUrl;
  link=`${this.apiUrl}/entite`;
    addEntite(entite:Entite):Observable<any>
    {
        return this.http.post(this.link,entite);
    }
    listeEntite():Observable<Entite[]>{
      return this.http.get<Entite[]>(this.link);
    }
    getEntitebyid(id:number):Observable<Entite>{
      return this.http.get<Entite>(this.link+`/${id}`);
    }
    updateEntite(id:number, entite:Entite):Observable<any>{
      return this.http.put(this.link+`/${id}`,entite);
    }
}
