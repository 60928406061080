import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Parametre } from './parametre';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ParametreService {

  constructor(
    private http:HttpClient
    ) { }
    apiUrl = environment.apiUrl;
    link=`${this.apiUrl}/parametre`;
    addParametre(parametre:Parametre):Observable<any>
    {
        return this.http.post(this.link,parametre);
    }
    listeParametre():Observable<Parametre[]>{
      return this.http.get<Parametre[]>(this.link);
    }
    getParametrebyid(id:number):Observable<Parametre>{
      return this.http.get<Parametre>(this.link+`/${id}`);
    }
    updateParametre(id:number, parametre:Parametre):Observable<any>{
      return this.http.put(this.http+`/${id}`,Parametre);
    }
}
