<div class="row col-md-10 text-center">

  <div class="block full">
    <!-- All Products Title -->
    <div class="block-title">
        <div class="block-options pull-right">
            <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default" data-toggle="tooltip" title="Settings"><i class="fa fa-cog"></i></a>
        </div>
        <h2><strong>Liste</strong> des services</h2>
        <button class="btn btn-success" (click)="redirectajouterentite()" ><span><i class="fa fa-plus"> Ajouter</i></span></button>
    </div>
    <table id="datatableexample" class="row-border hover">
        <thead>
            <tr>
                <th class="text-center">Departement</th>
                <th class="text-center">Direction</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entite of entites">
            <td class="text-center">{{entite['libelle'] }} </td>
            <td class="text-center">{{entite.id_entite }} </td>
            <td class="text-center">
              <button class="btn btn-default"(click)="redirectmodifierentite(entite['id'])">Modifier</button>
            </td>
          </tr>
        </tbody>
    </table>
    <!-- END All Products Content -->
  </div>

