import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from '../authentification.service';

@Component({
  selector: 'app-sidebbar',
  templateUrl: './sidebbar.component.html',
  styleUrls: ['./sidebbar.component.css']
})
export class SidebbarComponent implements OnInit {
role:any;
nom:any;
prenom:any;
Vmanager:string="";
Vuser:string="";

  constructor(
    public authentificationService:AuthentificationService
  ) { }

  ngOnInit(): void {
          this.role = localStorage.getItem('role');
          this.nom= localStorage.getItem('nom');
          this.prenom= localStorage.getItem('prenom');

  }

}
