import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Typemobilite } from './typemobilite';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TypemobiliteService {

  constructor(
    private http:HttpClient
    ) { }
    apiUrl = environment.apiUrl;
    link=`${this.apiUrl}/typemobilite`;
    addtypemobilite(typemobilite:Typemobilite):Observable<any>
    {
        return this.http.post(this.link,typemobilite);
    }
    listetypemobilite():Observable<Typemobilite[]>{
      return this.http.get<Typemobilite[]>(this.link);
    }
    gettypemobilitebyid(id:number):Observable<Typemobilite>{
      return this.http.get<Typemobilite>(this.link+`/${id}`);
    }
    updatetypemobilite(id:number, typemobilite:Typemobilite):Observable<any>{
      return this.http.put(this.http+`/${id}`,typemobilite);
    }
  }
