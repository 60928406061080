
  <div class="block full">
    <div class="block-title">
        <div class="block-options pull-right">
            <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default" data-toggle="tooltip" title="Settings"><i class="fa fa-cog"></i></a>
        </div>
        <h2><strong>Liste</strong> des utilisateurs</h2>
        <button class="btn btn-success" (click)="redirectajouterpersonne()" ><span><i class="fa fa-plus">Ajouter</i></span></button>
    </div>
    <flash-messages></flash-messages>
    <table id="datatableexample" class="row-border hover">
        <thead>
            <tr>
                <th class="text-center">Matricule</th>
                <th class="text-center">Nom</th>
                <th class="text-center">Prenom</th>
                <th class="text-center">Email</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let personne of personnes">
              <td class="text-center">{{personne['matricule'] }} </td>
              <td class="text-center">{{personne['nom'] }} </td>
              <td class="text-center"> {{personne['prenom'] }} </td>
              <td class="text-center">{{personne['email'] }} </td>
          <td>
              <button class="btn btn-primary"(click)="redirectmodifierpersonne(personne['id'])" > <i class="fa fa-pencil"></i></button>
              <button class="btn btn-danger"(click)="openModal(confirm, personne['id'])" > <i class="fa fa-repeat"></i></button>

            </td>
          </tr>
        </tbody>
    </table>
  </div>

  <ng-template #confirm>
    <div class="modal-body text-center">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Confirmez la réinitialisation du mot de passe</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"></button>
      <p>Voulez vous réinitialiser le mot de passe de l'utilisateur? Son nouveau mot de passe sera : anptic1234</p>
      <button type="button" class="btn btn-danger" (click)="renitiatiliser()" >Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
    </div>
    </div>

  </ng-template>
