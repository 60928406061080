import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthentificationService } from '../authentification.service';
import { NgForm } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { SidebbarComponent } from '../sidebbar/sidebbar.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
data: any;
  constructor(
    private authentificationService:AuthentificationService,
    private router:Router,
    private flashMessage: FlashMessagesService
  ) { }

  ngOnInit(): void {
  }
login(credential:NgForm){
  this.authentificationService.login(credential).subscribe(
    (resp)=>{
      this.data = resp;
      console.log(this.data.expires_in);
      const role= this.data.user.role;
      const name = this.data.user.nom;
      const prenom= this.data.user.prenom;
      const entite= this.data.user.entite_id;
      const firstcon= this.data.user.firstcon;
      localStorage.setItem('firstcon',firstcon);
      localStorage.setItem('nom',name)
      console.log(localStorage.getItem('nom'));
      localStorage.setItem('prenom',prenom)
      console.log(localStorage.getItem('prenom'));
      localStorage.setItem('role', role);
      localStorage.setItem('entite', entite);
      console.log(localStorage.getItem('role'));
      const user= this.data.user.id;
      localStorage.setItem('user_id', user );
      const token = this.data.access_token;
      localStorage.setItem('token', token);
      if(firstcon==1){
      if(role=='dg'|| role=='sg'){
        this.router.navigateByUrl('sidebarr', { skipLocationChange: true }).then(() =>
        { this.router.navigate(['tableaudemobilite']); });
      }
        if(role=='manager' || role=='admin'){
          this.router.navigate(["tableaumobilitedirecteur"]);
        }
        if(role=='user'){
          this.router.navigate(['mesmobilites']);
        }
      }
      else{
        this.router.navigate(['changerMonPass']);
      }

    },
    (error)=>{
      this.flashMessage.show('Email ou mot de passe incorrecte', {
        cssClass: 'custom-danger', timeout: 1500 });
    }
  )

}
}
