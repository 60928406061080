import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Mobilite } from '../mobilite';
import { MobiliteService } from '../mobilite.service';
import { Statmobilite } from '../statmobilite';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { decimalDigest } from '@angular/compiler/src/i18n/digest';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tableaudemobilite',
  templateUrl: './tableaudemobilite.component.html',
  styleUrls: ['./tableaudemobilite.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class TableaudemobiliteComponent implements OnInit {
  mobilites : Mobilite[]=[];
  mobilitesatistique: any;
  mobilite:any;
  dtOptions1: DataTables.Settings = {};
  bsModalRef: any;
  pipe = new DatePipe('en-US');
  modalRef: any;
  id:number=0;
  entite_id :any;
  conges:Mobilite[]=[];
  autorisations:Mobilite[]=[];
  missions:Mobilite[]=[];
  ateliers:Mobilite[]=[];

  constructor(
    config: NgbModalConfig,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private mobiliteService:MobiliteService,
    private modalService: BsModalService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;}

  ngOnInit(): void {
    this.mobiliteService.satistiqueMobilite().subscribe(
      (result)=>{
            this.mobilitesatistique=result;
      }
    );
    this.mobiliteService.listeMobilite().subscribe(
      (response)=>{
setTimeout(()=>{
      $('#datatableexample').DataTable( {
          language: {
            processing:     "Traitement en cours...",
            search:         "Rechercher&nbsp;:",
            lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
            info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
            infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Pr&eacute;c&eacute;dent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        },
          pagingType: 'full_numbers',
          pageLength: 5,
          processing: true,
          lengthMenu : [5, 10, 25],
        });
      }, 1);
        console.log(response);
        this.mobilites = response;
      }
    );


    this.mobiliteService.listerconge().subscribe(
      (result)=>{
            this.conges= result;
      }
    );
    this.mobiliteService.listeautorisation().subscribe(
      (auto)=>{
            this.autorisations= auto;
            console.log("ookookok"+this.autorisations);
      }
    );
    this.mobiliteService.listeratelier().subscribe(
      (dataAtelier)=>{
            this.ateliers= dataAtelier;
            console.log(this.ateliers);
      }
    );
    this.mobiliteService.listermission().subscribe(
      (datamission)=>{
            this.missions= datamission;
            console.log(this.missions);
      }
    );
  }
  detailmobilite(id:number){
    console.log(id);

        this.mobiliteService.getMobilitebyid(id).subscribe(
          (response)=>{
            this.mobilite =response;
            console.log(response)
            const initialState = {
              nom:response.nom,
              prenom : response.prenom,
              matricule: response.matricule,
              service: response.service,
              typemobilite: response.libelle,
              description:response.description,
              lieu: response.lieu,
              datedebut: this.pipe.transform(response.datedebut, 'dd-MM-yyyy'),
              datefin: this.pipe.transform(response.datefin, 'dd-MM-yyyy'),
            };
            this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
            this.bsModalRef.content.closeBtnName = 'Close';
          },
          (error)=>{
            alert("Probleme d'access aux api");
          }
        );


  }
  openModal(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template,{class: 'modal-lg'});

}

}

@Component({
  selector: 'modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">Details de la Mobilite</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

         <span class="col-md-4 offset-md-4" > <strong>Matricule :</strong> </span>  <span class="col-md-6" >{{ matricule }} </span>
        <hr>
        <span class="col-md-4 offset-md-4" >  <strong>Nom :</strong> </span> <span class="col-md-6" >{{ nom }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Prenom :</strong> </span> <span class="col-md-4" >{{ prenom }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Service :</strong> </span> <span class="col-md-4" >{{ service}} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>En :</strong> </span> <span class="col-md-4" style="color:red;" >{{ typemobilite }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Du :</strong> </span> <span class="col-md-4" >{{ datedebut }}  </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Au :</strong> </span> <span class="col-md-4" >{{ datefin }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Lieu :</strong> </span> <span class="col-md-4" >{{ lieu }} </span> <br>
       <hr>
       <span class="col-md-4 offset-md-4" >  <strong>Description :</strong> </span> <span class="col-md-4" > {{ description }} </span> <br>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Fermer</button>
    </div>
  `
})

export class ModalContentComponent implements OnInit {
  title: string='';
  description: string='';
  nom: string='';
  prenom: string='';
  typemobilite: any;
  matricule: string='';
  datedebut: any;
  datefin: any;
  service: string="";
  lieu: string='';
  list: any[] = [];

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.list.push('PROFIT!!!');
  }
}
