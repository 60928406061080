import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { PersonneService } from '../personne.service';
import { Personne } from '../personne';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-listerpersonne',
  templateUrl: './listerpersonne.component.html',
  styleUrls: ['./listerpersonne.component.css']
})
export class ListerpersonneComponent implements OnInit {
personnes:Personne[]=[];
dtOptions2: DataTables.Settings = {};
modalRef: any;
id:number=0;
  constructor(
    private personneService : PersonneService,
    private router:Router,
    private flashMessage: FlashMessagesService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.personneService.listePersonne().subscribe(
      (response)=>{
        setTimeout(()=>{
          $('#datatableexample').DataTable( {
            language: {
              processing:     "Traitement en cours...",
              search:         "Rechercher&nbsp;:",
              lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
              info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
              infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
              infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
              infoPostFix:    "",
              loadingRecords: "Chargement en cours...",
              zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
              emptyTable:     "Aucune donnée disponible dans le tableau",
              paginate: {
                  first:      "Premier",
                  previous:   "Pr&eacute;c&eacute;dent",
                  next:       "Suivant",
                  last:       "Dernier"
              },
              aria: {
                  sortAscending:  ": activer pour trier la colonne par ordre croissant",
                  sortDescending: ": activer pour trier la colonne par ordre décroissant"
              }
          },
            pagingType: 'full_numbers',
            pageLength: 5,
            processing: true,
            lengthMenu : [5, 10, 25],
            order:[[1,"desc"]]
        } );
        }, 1);
        this.personnes=response;
      }
    );
  }
  redirectajouterpersonne(){
    this.router.navigate(['ajouterpersonne']);
  }
  redirectmodifierpersonne(id:number){
    this.router.navigate(['modifierpersonne', id]);
  }
  openModal(template: TemplateRef<any>, id:number) {
    this.id=id;
  this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
}
  renitiatiliser(){
      this.personneService.renitiatiliser(this.id).subscribe(
    (response)=>{
      window.location.reload();
      this.flashMessage.show('Votre mobilite a été enregistre avec success', {
        cssClass: 'custom-success', timeout: 3000 });
    }
      );
      this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

}
