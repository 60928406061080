import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobiliteService } from '../mobilite.service';
import { PersonneService } from '../personne.service';
import { Personne } from '../personne';
import { Mobilite } from '../mobilite';
import { ValeurService } from '../valeur.service';
import { Valeur } from '../valeur';
import { AuthentificationService } from '../authentification.service';
import {Location} from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
defineLocale('fr', frLocale);




@Component({
  selector: 'app-modifiermobilite',
  templateUrl: './modifiermobilite.component.html',
  styleUrls: ['./modifiermobilite.component.css']
})
export class ModifiermobiliteComponent implements OnInit {

mobilite: Mobilite=  {id: 1, typemobilite:1,datedebut:"", datefin:'', matricule:'',nom:'', prenom:'',service:'',description:'',lieu:'', libelle:'',entiteparent:'',typeconge:''};
id: number=0;
today: any;
minDate: any;
datedebut : any;
datefin : any;
valeurs:Valeur[]=[];
minDate1:any;
personnes:Personne[]=[];
locale = 'fr';

  constructor(
    private activatedRoute:ActivatedRoute,
    private Router:Router,
    private authentificationService:AuthentificationService,
    private mobiliteService:MobiliteService,
    private personneService:PersonneService,
    private valeurService:ValeurService,
    private _location: Location,
    private localeService: BsLocaleService

  ) { }

  ngOnInit(): void {
    this.today = new Date();
    this.minDate1 = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() , 2);
    this.minDate = new Date(this.today.getFullYear(), this.today.getMonth(), 2);
    this.localeService.use('fr');
    this.activatedRoute.params.subscribe(
      (params)=>{
       this.mobiliteService.getMobilitebyid(params.id).subscribe(
         (response)=>{
           this.mobilite = response;
           console.log(this.mobilite);
           this.id=params.id;
           this.datedebut= this.mobilite.datedebut;
           this.datedebut= new Date(this.datedebut) ;
           this.mobilite.datedebut= this.datedebut;
           this.datefin= this.mobilite.datefin;
           this.datefin= new Date(this.datefin) ;
           this.mobilite.datefin=this.datefin;
         },
         (error)=>{
           alert("probleme d'acces aux api");
         }
      );
  }

    );
    this.valeurService.listeValeur().subscribe(
      (retour)=>{
        this.valeurs=retour;
      }
    );
this.personneService.listePersonne().subscribe(
    (result)=>{
      this.personnes=result;
    }
);

}
applyLocale(pop: any) {
  this.localeService.use(this.locale);
  pop.hide();
  pop.show();
}
modifiermobilite(){
  console.log(this.id);
  this.mobiliteService.updateMobilite(this.id,this.mobilite).subscribe(
    (result)=>{
        this.redirectback();

    },
(error)=>{
  alert("Probleme d'acces aux API");
}
  );
  }
  redirectback() {
    this._location.back();
  }

}
