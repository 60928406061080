import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonneService } from '../personne.service';

@Component({
  selector: 'app-update-user-password',
  templateUrl: './update-user-password.component.html',
  styleUrls: ['./update-user-password.component.css']
})
export class UpdateUserPasswordComponent implements OnInit {
  user:any;
  id:number=0;
  pass:string='';
  constructor(
    private PersonneService:PersonneService,
    private router: Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const x = localStorage.getItem('user_id');
    this.id=Number(x)
      this.PersonneService.getPersonnebyid(this.id).subscribe(
        (result)=>{
          this.user=result;
          console.log(this.user)
        }
      )
  }
  updateuserpass(){
    this.PersonneService.changerMonPass(this.id, this.user).subscribe(
      (response)=>{
        const role= localStorage.getItem('role')
        localStorage.removeItem('firstcon')
        localStorage.setItem('firstcon', '1')
        if(role=='dg'|| role=='sg'){
          this.router.navigateByUrl('sidebarr', { skipLocationChange: true }).then(() =>
          { this.router.navigate(['tableaudemobilite']); });
        }
          if(role=='manager' || role=='admin'){
            this.router.navigate(["tableaumobilitedirecteur"]);
          }
          if(role=='user'){
            this.router.navigate(['mesmobilites']);
          }
      }
    )
  }

}
