<div class="row text-center mb2 ">
  <div class="col-sm-6 col-lg-3">
    <a (click)="openModal(congess)" href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background-danger">
            <h4 class="widget-content-light"><strong>EN CONGE</strong></h4>
        </div>
        <div class="widget-extra-full"><span class="h2 text-danger animation-expandOpen">{{ mobilitesatistique['nombreconge'] }}</span></div>
    </a>
</div>
  <div class="col-sm-6 col-lg-3">
     <a (click)="openModal(autorisationss)" href="javascript:void(0)" class="widget widget-hover-effect2">
          <div class="widget-extra themed-background-warning">
              <h4 class="widget-content-light"><strong>EN AUTORISATION</strong> </h4>
          </div>
          <div class="widget-extra-full"><span class="h2 text-danger animation-expandOpen">{{ mobilitesatistique['nombreautorisation'] }}</span></div>
      </a>
  </div>
  <div class="col-sm-6 col-lg-3">
     <a (click)="openModal(missionss)" href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background-success">
            <h4 class="widget-content-light"><strong>EN MISSION</strong>  </h4>
        </div>
        <div class="widget-extra-full"><span class="h2 themed-color-dark animation-expandOpen">{{ mobilitesatistique['nombremission'] }}</span></div>
    </a>
</div>
  <div class="col-sm-6 col-lg-3">
      <a (click)="openModal(atelierss)" href="javascript:void(0)" class="widget widget-hover-effect2">
          <div class="widget-extra themed-background-dark">
              <h4 class="widget-content-light"><strong> EN ATELIER</strong></h4>
          </div>
          <div class="widget-extra-full"><span class="h2 themed-color-dark animation-expandOpen">{{ mobilitesatistique['nombreatelier'] }}</span></div>
      </a>
  </div>
</div>
<!-- END Quick Stats -->

<!-- All Products Block -->
<div class="block full">
  <!-- All Products Title -->
  <div class="block-title">
      <div class="block-options pull-right">
          <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default" data-toggle="tooltip" title="Settings"><i class="fa fa-cog"></i></a>
      </div>
      <h2><strong>Liste</strong> des agents indisponibles</h2>

  </div>
  <table id="datatableexample"  class="row-border hover">
    <thead>
      <tr>
        <th class="text-left">Service Parent</th>
        <th class="text-left">Service</th>
      <th class="text-left">Matricule</th>
      <th class="text-left">Nom / Prénom</th>
      <th class="text-left">Période</th>
      <th class="text-left" style="width: 10%;">Position</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mobilite of mobilites">
        <td class="text-left">{{ mobilite['entiteparent'] }}</td>
        <td class="text-left">{{ mobilite['service'] }}</td>
        <td class="text-left">{{mobilite['matricule'] }} </td>
         <td class="text-left">{{mobilite['nom'] }} {{mobilite['prenom']}}</td>
        <td class="text-left"> <strong>du :</strong> {{mobilite['datedebut'] | date: 'dd-MM-yyyy'}}  <strong> au :</strong>  {{mobilite['datefin'] | date: 'dd-MM-yyyy'}} </td>
        <td *ngIf="mobilite['libelle']=='Mission'" style="background-color: #479cd4;">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
        <td *ngIf="mobilite['libelle']=='Conge'" style="background-color:#b14b41">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
        <td *ngIf="mobilite['libelle']=='Autorisation absence'" style="background-color:#ea7967;">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
        <td *ngIf="mobilite['libelle']=='Atelier'" style="background-color: #91b5cc ;">
          <a routerLink="['tableaudemobilite']" (click)="detailmobilite(mobilite['id'])">{{mobilite['libelle'] }}</a>
        </td>
      </tr>
    </tbody>
</table>
  <!-- END All Products Content -->
</div>
<ng-template #congess>
  <div class="modal-body text-center">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Liste des agents en congé</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table id="datatableexample"  class="row-border hover">
        <thead>
          <tr>
          <th class="text-left col-sm-2">Service Parent</th>
          <th class="text-left col-sm-2">Matricule</th>
          <th class="text-left col-sm-4">Nom / Prénom</th>
          <th class="text-left col-sm-2" >Date debut</th>
          <th class="text-left col-sm-2" >Date fin</th>
        </tr>
        </thead>
        <tbody >
          <tr *ngFor="let conge of conges">
            <td class="text-left col-sm-2">{{ conge['entiteparent'] }}</td>
            <td class="text-left col-sm-2">{{ conge['service'] }}</td>
            <td class="text-left col-sm-2">{{conge['matricule'] }} </td>
             <td class="text-left col-sm-4">{{conge['nom'] }} {{conge['prenom']}}</td>
            <td class="text-left col-sm-2"> {{conge['datedebut'] | date: 'dd-MM-yyyy'}}   </td>
            <td class="text-left col-sm-2">{{conge['datefin'] | date: 'dd-MM-yyyy'}}</td>
          </tr>
        </tbody>
    </table>
  </div>
  </div>

</ng-template>

<ng-template #autorisationss>
  <div class="modal-body text-center">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Liste des agents en autorisation</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table id="datatableexample"  class="row-border hover">
        <thead>
          <tr>
            <th class="text-left col-sm-2">Service Parent</th>
          <th class="text-left col-sm-2">Matricule</th>
          <th class="text-left col-sm-4">Nom / Prénom</th>
          <th class="text-left col-sm-2">Date debut</th>
          <th class="text-left col-sm-2">Date Fin</th>

        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let autorisation of autorisations">
            <td class="text-left col-sm-2">{{ autorisation['entiteparent'] }}</td>
            <td class="text-left col-sm-2">{{autorisation['matricule'] }} </td>
             <td class="text-left col-sm-4">{{autorisation['nom'] }} {{autorisation['prenom']}}</td>
            <td class="text-left col-sm-2"> {{autorisation['datedebut'] | date: 'dd-MM-yyyy'}} </td>
            <td class="text-left col-sm-2">{{autorisation['datefin'] | date: 'dd-MM-yyyy'}} </td>
          </tr>
        </tbody>
    </table>
  </div>
  </div>

</ng-template>

<ng-template #missionss>
  <div class="modal-body text-center">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Liste des agents en mission</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table id="datatableexample"  class="row-border hover">
        <thead>
          <tr>
            <th class="text-left col-sm-2">Service Parent</th>
            <th class="text-left col-sm-2">Service</th>
          <th class="text-left col-sm-2">Matricule</th>
          <th class="text-left col-sm-4">Nom / Prénom</th>
          <th class="text-left col-sm-2">Période</th>

        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let mission of missions">
            <td class="text-left col-sm-2">{{ mission['entiteparent'] }}</td>
            <td class="text-left col-sm-2">{{ mission['service'] }}</td>
            <td class="text-left col-sm-2">{{mission['matricule'] }} </td>
             <td class="text-left col-sm-4">{{mission['nom'] }} {{mission['prenom']}}</td>
            <td class="text-left col-sm-2"> <strong>du :</strong> {{mission['datedebut'] | date: 'dd-MM-yyyy'}}  <strong> au :</strong>  {{mission['datefin'] | date: 'dd-MM-yyyy'}} </td>
          </tr>
        </tbody>
    </table>
  </div>
  </div>

</ng-template>

<ng-template #atelierss>
  <div class="modal-body text-center">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Liste des agents en atelier</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table id="datatableexample"  class="row-border hover">
        <thead>
          <tr>
            <th class="text-left col-sm-2">Service Parent</th>
            <th class="text-left col-sm-2">Service</th>
          <th class="text-left col-sm-2">Matricule</th>
          <th class="text-left col-sm-4">Nom / Prénom</th>
          <th class="text-left col-sm-2">Période</th>

        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let atelier of ateliers">
            <td class="text-left col-sm-2">{{ atelier['entiteparent'] }}</td>
            <td class="text-left col-sm-2">{{ atelier['service'] }}</td>
            <td class="text-left col-sm-2">{{atelier['matricule'] }} </td>
             <td class="text-left col-sm-4">{{atelier['nom'] }} {{atelier['prenom']}}</td>
            <td class="text-left col-sm-2"> <strong>du :</strong> {{atelier['datedebut'] | date: 'dd-MM-yyyy'}}  <strong> au :</strong>  {{mobilite['datefin'] | date: 'dd-MM-yyyy'}} </td>
          </tr>
        </tbody>
    </table>
  </div>
  </div>

</ng-template>
