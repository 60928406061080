<div id="sidebar" *ngIf="authentificationService.isLogged() && authentificationService.isfirstconn()">
  <!-- Wrapper for scrolling functionality -->
  <div id="sidebar-scroll">

      <div class="sidebar-content">
          <div class="sidebar-section sidebar-user">
              <div class="sidebar-user-avatar">

                      <img src="assets/img/logo_anptic.png">

              </div>
              <div class="sidebar-user-name">{{  authentificationService.getusername() }}</div>
          </div>
          <ul class="sidebar-nav">
              <li *ngIf="authentificationService.isdg() || authentificationService.isadmin() || authentificationService.issg()">
                  <a [routerLink]= "['tableaudemobilite']"  routerLinkActive="active"><i class="gi gi-charts sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Tableau de bord</span></a>
              </li>
              <li *ngIf="authentificationService.isdg() || authentificationService.isadmin() || authentificationService.issg()">
                <a [routerLink]= "['allmobilites']"  routerLinkActive="active"><i class="gi gi-charts sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Toutes les mobilites</span></a>
            </li>
              <li *ngIf="authentificationService.ismanger()">
                <a [routerLink]= "['tableaumobilitedirecteur']"  routerLinkActive="active"><i class="gi gi-edit sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide"></span>Tableau de bord</a>
              </li>
              <li *ngIf="authentificationService.isdg() || authentificationService.isadmin() || authentificationService.issg()" >
                <a [routerLink]= "['mobilitesAvenir']"  routerLinkActive="active"><i class="gi gi-list sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide"></span>Mobilites futures </a>
            </li>
              <li *ngIf="authentificationService.isadmin() || authentificationService.ismanger()|| authentificationService.issg()">
                <a [routerLink]= "['listemobiliteparentite']"  routerLinkActive="active"><i class="gi gi-edit sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide"></span>Mobilite des agents</a>
              </li>
            <li *ngIf="authentificationService.isadmin() || authentificationService.isdg() || authentificationService.ismanger() || authentificationService.isuser() || authentificationService.issg()">
              <a [routerLink]= "['mesmobilites']"  routerLinkActive="active"><i class="gi gi-edit sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide"></span>Signaler ma mobilite</a>
            </li>
            <li *ngIf="authentificationService.isadmin()">
              <a [routerLink]= "['listevaleur']"  routerLinkActive="active"><i class="gi gi-cogwheel sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide"></span>Valeurs</a>
            </li>
            <li *ngIf="authentificationService.isadmin()">
              <a [routerLink]= "['listentite']"  routerLinkActive="active"><i class="gi gi-bank sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide"></span>Entite</a>
            </li>
            <li *ngIf="authentificationService.isadmin()">
              <a [routerLink]= "['listerpersonne']"  routerLinkActive="active"><i class="gi gi-user sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide"></span>Utilisateurs</a>
            </li>
          </ul>
      </div>
  </div>
  <!-- END Wrapper for scrolling functionality -->
</div>
