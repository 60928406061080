import { Component, OnInit } from '@angular/core';
import { EntiteService } from '../entite.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Entite } from '../../../e2e/src/entite';

@Component({
  selector: 'app-ajouterentite',
  templateUrl: './ajouterentite.component.html',
  styleUrls: ['./ajouterentite.component.css']
})
export class AjouterentiteComponent implements OnInit {
  entites:Entite[]=[];
  constructor(
    private entiteService:EntiteService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.entiteService.listeEntite().subscribe(
      (response)=>{
        this.entites=response;
      },
      (error)=>{
        alert("Probleme d'acces aux Api");
      }
    );
  }
  ajouterentite(formulaire:NgForm){
    this.entiteService.addEntite(formulaire.value).subscribe(
      (response)=>{
        this.router.navigate(['listentite']);
      },
      (error)=>{
        alert("probleme d'acces à l'api");
      }

    );
  }
}
