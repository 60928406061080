<div class="col-md-10">
  <!-- Basic Form Elements Block -->
  <div class="block">
      <!-- Basic Form Elements Title -->
      <div class="block-title">
          <div class="block-options pull-right">
              <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default toggle-bordered enable-tooltip" data-toggle="button" title="Toggles .form-bordered class"></a>
          </div>
          <h2><strong>Enregistrer</strong> type de mobilite</h2>
      </div>
      <!-- END Form Elements Title -->

      <!-- Basic Form Elements Content -->
      <form #formtypemobilite="ngForm" class="form-horizontal form-bordered">

        <div class="form-group">
          <label class="col-md-3 control-label" for="example-datepicker">Libelle</label>
          <div class="col-md-6">
              <input type="text" ngModel  name="libelle" class="form-control" >
          </div>
        </div>
        <div class="form-group form-actions">
              <div class="col-md-9 col-md-offset-3">
                <button [disabled]="formtypemobilite.invalid" (click)= "ajoutetypemobilite(formtypemobilite)" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Enregistrer</button>
              </div>
          </div>
      </form>
      <!-- END Basic Form Elements Content -->
  </div>
  <!-- END Basic Form Elements Block -->
</div>
