import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TypemobiliteService } from '../typemobilite.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ajoutertypemobilite',
  templateUrl: './ajoutertypemobilite.component.html',
  styleUrls: ['./ajoutertypemobilite.component.css']
})
export class AjoutertypemobiliteComponent implements OnInit {

  constructor(
    private router:Router,
    private typemobiliteservice:TypemobiliteService) { }

  ngOnInit(): void {
  }
   ajoutetypemobilite(formulaire:NgForm){
     this.typemobiliteservice.addtypemobilite(formulaire.value).subscribe(
      (response)=>{
        this.router.navigate(['listetypemobilte']);
      },
      (error)=>{
        alert("probleme d'acces à l'api");
      }
     );
   }
}
