import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Mobilite } from '../mobilite';
import { MobiliteService } from '../mobilite.service';
import localeFr from '@angular/common/locales/fr'; // to register french
import { frLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
defineLocale('fr', frLocale);
@Component({
  selector: 'app-mesmobilite',
  templateUrl: './mesmobilite.component.html',
  styleUrls: ['./mesmobilite.component.css']
})
export class MesmobiliteComponent implements OnInit {
mobilites:Mobilite[]=[];
id:number =0;
modalRef: any;
  constructor(
    private router:Router,
    private mobiliteService:MobiliteService,
    private modalService: BsModalService,
    private flashMessage: FlashMessagesService,
    private localeService: BsLocaleService
  ) { }

  ngOnInit(): void {
    this.localeService.use('fr');
    const user_id= localStorage.getItem('user_id');
    const id=Number(user_id);
this.mobiliteService.getmesmobilites(id).subscribe(
  (response)=>{
    setTimeout(()=>{
      $('#datatableexample').DataTable( {
        language: {
          processing:     "Traitement en cours...",
          search:         "Rechercher&nbsp;:",
          lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
          info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix:    "",
          loadingRecords: "Chargement en cours...",
          zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable:     "Aucune donnée disponible dans le tableau",
          paginate: {
              first:      "Premier",
              previous:   "Pr&eacute;c&eacute;dent",
              next:       "Suivant",
              last:       "Dernier"
          },
          aria: {
              sortAscending:  ": activer pour trier la colonne par ordre croissant",
              sortDescending: ": activer pour trier la colonne par ordre décroissant"
          }
      },
        pagingType: 'full_numbers',
        pageLength: 5,
        processing: true,
        lengthMenu : [5, 10, 25],
        order:[[1,"desc"]]
    } );
    }, 1);
    this.mobilites= response;
  },
  (error)=>{
alert("Probleme d'acces aux API");
  }
);
  }
  redirectajoutermobilite(){
    this.router.navigate(['mamobilite']);
    }
  public redirectmodifiermobilite(id:number){
  this.router.navigate(['modifiermobilite',id]);
  }
  openModal(template: TemplateRef<any>, id:number) {
    this.id=id;
  this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
}
supprimer(){
  this.mobiliteService.deletemobilite(this.id).subscribe(
    (response)=>{
      window.location.reload();
      this.flashMessage.show('Votre mobilite a été enregistre avec success', {
        cssClass: 'custom-success', timeout: 3000 });
    }
  )
}
decline(): void {
  this.modalRef.hide();
}
}
