import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modifiertypemobilite',
  templateUrl: './modifiertypemobilite.component.html',
  styleUrls: ['./modifiertypemobilite.component.css']
})
export class ModifiertypemobiliteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
