import { Component, OnInit, TemplateRef } from '@angular/core';
import { MobiliteService } from '../mobilite.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-detailmobilite',
  templateUrl: './detailmobilite.component.html',
  styleUrls: ['./detailmobilite.component.css']
})
export class DetailmobiliteComponent {
  modalRef: any;
  constructor(
    private mobiliteService:MobiliteService,
    private router:Router,
    private ActivatedRoute:ActivatedRoute,
    private modalService: BsModalService
  ) {
 }

 openModal(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template);
}

}
