<div class="col-md-10">
  <!-- Basic Form Elements Block -->
  <div class="block">
      <!-- Basic Form Elements Title -->
      <div class="block-title">
          <div class="block-options pull-right">
              <a href="javascript:void(0)" class="btn btn-alt btn-sm btn-default toggle-bordered enable-tooltip" data-toggle="button" title="Toggles .form-bordered class"></a>
          </div>
          <h2><strong>Modifier les information de l'utilisateur {{ personne['id'] }}</strong> </h2>
      </div>

      <!-- END Form Elements Title -->

      <!-- Basic Form Elements Content -->
      <form #formvaleur="ngForm" class="form-horizontal form-bordered">
        <div class="form-group">
          <label class="col-md-3  control-label" for="matricule">Matricule</label>
          <div class="col-md-6">
            <input type="text"
                    [(ngModel)]="personne.matricule"
                     name="matricule"
                     id="matricule"
                     class="form-control" #matricule="ngModel">

          </div>

        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="nom">Nom</label>
          <div class="col-md-6">
            <input type="text" [(ngModel)]="personne.nom"   name="nom" class="form-control" >
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="prenom">Prenom</label>
          <div class="col-md-6">
            <input type="text" [(ngModel)]="personne.prenom"   name="prenom" class="form-control" >
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="email">Email</label>
          <div class="col-md-6">
            <input type="text" [(ngModel)]="personne.email"   name="email" class="form-control" >
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="example-chosen">Role</label>
            <div class="col-md-6">
              <select name="role" class="form-control" [(ngModel)]="personne.role" id="" style="width: 100%;">
                <option [value]="role[0]">User</option>
                <option [value]="role[1]">DG</option>
                <option [value]="role[4]">SG</option>
                <option [value]="role[2]">Administrateur</option>
                <option [value]="role[3]" >Manager</option>
            </select>
            </div>
          </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="example-chosen">Service</label>
            <div class="col-md-6">
              <select name="entite_id" class="form-control" [(ngModel)]="personne.entite_id" id="" style="width: 100%;">
                <option  *ngFor="let entite of entites" [value]="entite.id">{{ entite.libelle }}</option>
            </select>
            </div>
          </div>
        <div class="form-group form-actions">
              <div class="col-md-9 col-md-offset-3">
                <button [disabled]="formvaleur.invalid" (click)= "modifierpersonne()" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i> Enregistrer</button>
                <button class="btn btn-sm btn-warning mr2" style="margin-left: 200px;" (click)="redirectback()" ><i class="fa fa-repeat"></i> Annuler</button>
              </div>
          </div>
      </form>
      <!-- END Basic Form Elements Content -->
  </div>
  <!-- END Basic Form Elements Block -->
</div>

