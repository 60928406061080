import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Personne } from './personne';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PersonneService {

  constructor(
    private http:HttpClient
  ) { }
  apiUrl = environment.apiUrl;
  link=`${this.apiUrl}/users`;
      addPersonne(personne:Personne):Observable<any>
    {
        return this.http.post(`${this.apiUrl}/register`,personne);
    }
    listePersonne():Observable<Personne[]>{
      return this.http.get<Personne[]>(this.link);
    }
    getPersonnebyid(id:number):Observable<Personne>{
      return this.http.get<Personne>(this.link+`/${id}`);
    }
    updatePersonne(id:number, personne:Personne):Observable<any>{
      return this.http.put(`${this.apiUrl}/userupdate/id`,personne);
    }
    listePersonneparentite(id:number):Observable<Personne[]>{
      return this.http.get<Personne[]>(`${this.apiUrl}/listeuserparentite`+`/${id}`);
    }
    renitiatiliser(id:number):Observable<any>{
      return this.http.get(`${this.apiUrl}/reinitialiser/${id}`);
    }
    changerMonPass(id:number, personne:Personne):Observable<any>{
      return this.http.put(`${this.apiUrl}/changerMonPass/${id}`, personne);
    }
}
