import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonneService } from '../personne.service';
import { EntiteService } from '../entite.service';
import { Entite } from '../../../e2e/src/entite';

@Component({
  selector: 'app-ajouterpersonne',
  templateUrl: './ajouterpersonne.component.html',
  styleUrls: ['./ajouterpersonne.component.css']
})
export class AjouterpersonneComponent implements OnInit {
entites: Entite[]=[];
  constructor(
    private personneService:PersonneService,
    private router:Router,
    private entiteService:EntiteService
  ) { }

  ngOnInit(): void {
    this.entiteService.listeEntite().subscribe(
      (response)=>{
        this.entites = response;
      },
    (error)=>{
      alert("Probleme d'acces aux api");
    }
    );
  }
  ajouterpersonne(formulaire:NgForm){
    console.log(formulaire.value);
    this.personneService.addPersonne(formulaire.value).subscribe(
      (response)=>{
        this.router.navigate(['listerpersonne']);
      },
      (error)=>{
        alert(error);
      }
      );

    }
    redirectback(){
      this.router.navigate(['listerpersonne']);
    }

}
