import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { param } from 'jquery';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Mobilite } from '../mobilite';
import { MobiliteService } from '../mobilite.service';

@Component({
  selector: 'app-listemobiliteparentite',
  templateUrl: './listemobiliteparentite.component.html',
  styleUrls: ['./listemobiliteparentite.component.css']
})
export class ListemobiliteparentiteComponent implements OnInit {
 entite_id:any;
 mobilites:Mobilite[]=[];
 modalRef: any;
id:number=0;
  constructor(
    private mobiliteService:MobiliteService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private flashMessage: FlashMessagesService,
     private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.entite_id= localStorage.getItem('entite');
      this.mobiliteService.mobiliteparentites( this.entite_id).subscribe(
        (response)=>{
          setTimeout(()=>{
            $('#datatableexample').DataTable( {
              language: {
                processing:     "Traitement en cours...",
                search:         "Rechercher&nbsp;:",
                lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
                info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                infoPostFix:    "",
                loadingRecords: "Chargement en cours...",
                zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
                emptyTable:     "Aucune donnée disponible dans le tableau",
                paginate: {
                    first:      "Premier",
                    previous:   "Pr&eacute;c&eacute;dent",
                    next:       "Suivant",
                    last:       "Dernier"
                },
                aria: {
                    sortAscending:  ": activer pour trier la colonne par ordre croissant",
                    sortDescending: ": activer pour trier la colonne par ordre décroissant"
                }
            },
              pagingType: 'full_numbers',
              pageLength: 5,
              processing: true,
              lengthMenu : [5, 10, 25],
              order:[[1,"desc"]]
          } );
          }, 1);
              this.mobilites= response;
              console.log(response);
        }
      )
  }
  redirectajoutermobilite(){
    this.router.navigate(['ajoutermobilite']);
  }
  redirectmodifiermobilite(id:number){
    this.router.navigate(['modifiermobilite', id]);
    }
    openModal(template: TemplateRef<any>, id:number) {
      this.id=id;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }
    supprimermobilite(){
    this.mobiliteService.deletemobilite(this.id).subscribe(
      (response)=>{
        window.location.reload();
        this.flashMessage.show('Enregistrement a été supprimé avec succes', {
          cssClass: 'custom-success', timeout: 3000 });
      }
    );
    this.modalRef.hide();
    }

  decline(): void {
    this.modalRef.hide();
  }
}
