import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PersonneService } from '../personne.service';

@Component({
  selector: 'app-updateuser',
  templateUrl: './updateuser.component.html',
  styleUrls: ['./updateuser.component.css']
})
export class UpdateuserComponent implements OnInit {
user:any;
id:number=0;
  constructor(
    private PersonneService:PersonneService,
    private router: Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const x = localStorage.getItem('user_id');
    console.log(x);
    this.id=Number(x)
  this.PersonneService.getPersonnebyid(this.id).subscribe(
          (user)=>{
                this.user=user;
          }
        );
  }
  update(){
    console.log(this.user);
    this.PersonneService.updatePersonne(this.id,this.user).subscribe(
      (retour)=>{
        this.router.navigate(['mamobilite']);
      },
      (error)=>{
        alert("probleme d'acces aux API");
      }
    )

  }

}
